<template>
  <keep-alive>
    <router-view :key="$route.fullPath"></router-view>
  </keep-alive>
</template>

<script>
	export default {
		name: "admin-image-wrapper",
	};
</script>

<style scoped>
</style>
