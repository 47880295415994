<template>
  <div class="c-main">
    <h2 class="c-title c-title--page">{{$t('image.edit')}}</h2>
    <displayImage @submit="update($event)" @remove="remove($event)"></displayImage>
  </div>
</template>

<script>
  export default {
    name: "admin-image-view",
    components: {
      displayImage: require('./image.vue').default,
    },
    methods:{
      update: function(display) {
        console.log('image submit');
        this.$store.dispatch('displayImage.update', display).then(() => {
          this.$navigation.goto({ name: 'admin.event.display.images.list' });
        });
      },
      remove: function (imageId) {
        this.$store.dispatch('displayImage.remove', {
          id: imageId
        }).then(() => {
          this.$navigation.goto({ name: 'admin.event.display.images.list' });
        });
      }
    },
  };
</script>

<style>
</style>
