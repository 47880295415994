<template>
  <div class="c-main">
    <div class="c-main--flex-1">
      <h2 class="c-title c-title--page">{{session.name}}: {{ $t('panels') }}</h2>

      <div class="o-layout u-margin-bottom-large" v-for="set of session.sets" :key="set">
        <div class="o-layout__item">
          <h3 class="c-title c-title--page-section">{{ $t('set') }} {{ set }}</h3>
        </div>
        <div class="o-layout__item c-button--group u-1-of-1">
          <sceRouteButton :route="{ name: 'admin.event.discipline.sessions.panel', params: { panelId: panel.id }}"
                          v-for="panel of getSetPanels(set)" :key="panel.id" class="c-button--primary c-button--large"
                          :label="getPanelTitle(panel)"
                          :extraLabel="panel.exerciseTypeId ? 'exercise.type.' + panel.exerciseTypeId : null" />
        </div>
      </div>

      <div class="o-layout">
        <div class="o-layout__item">
          <h3 class="c-title c-title--page-section">{{ $t('panels.copy') }}</h3>
        </div>

        <div class="o-layout__item u-1-of-2-at-small">
          <sceSelect v-model="copySession" :options="sessions" :config="{emptyLabel: 'none', localized: false}" />
          <sceButton v-on:click="copyPanels()" label="copy" :disabled="! copySession" class="c-button--primary"/>
        </div>
        <div class="o-layout__item u-1-of-2-at-small">
          <div class="c-input--extra-info">
            {{ $t('panels.copy.warning') }}
          </div>
        </div>
      </div>
    </div>

    <footer class="c-footer c-footer--bottom-sticky c-footer--bottom-sticky-fixed-height o-layout">
      <div class="o-layout__item u-1-of-2-at-tiny">
      </div>
      <div class="o-layout__item u-1-of-2-at-tiny u-text-right">
        <sceRouteButton :route="{ name: 'admin.event.discipline.sessions'}" label="back"
                        class="c-button--ghost c-button--large" />
      </div>
    </footer>
  </div>
</template>

<script>

import config from "@/config";

const sortBy = require('lodash/sortBy')

import panelLib from 'client/lib/panelHandler'

export default {
  name: 'sessionPanels',
  data: function() {
    return {
      copySession: null
    }
  },
  computed: {
    session: function() {
      return this.$store.state.sessions.items.find(session => session.id === this.$route.params.sessionId)
    },
    sessions: function() {
      return this.$store.state.sessions.items.filter(session => session.id !== this.$route.params.sessionId)
    },
    panels: function() {
      return sortBy(this.$store.state.panels.items.filter(panel => panel.sessionId === this.$route.params.sessionId)
        , ['set', 'index'])
    },
  },
  methods: {
    getPanelTitle: function(panel) {
      return panelLib.panelName(panel, 'small')
    },
    getSetPanels: function(set) {
      return this.panels.filter(p => p.set === set)
    },
    copyPanels: function() {
      this.$notify.msgAsync(
        this.$http.post(config.root + '/sessions/' + this.session.id + '/copyPanels', {
          sessionId: this.copySession,
        }),
        this.$t('panels.copying'),
        this.$t('panels.copied'),
        this.$t('panels.copyFailed')
      )
    },
  },
}
</script>

<style scoped>

</style>
