<template>
  <div class="c-main">
    <h3 class="c-title c-title--page-section">{{$t('team.add')}}</h3>

    <team @submit="update($event)"></team>
  </div>
</template>

<script>
	export default {
		name: "team-add",
    components: {
      team: require('./team.vue').default,
    },
		methods: {
			update: function(team) {
        this.$notify.saveAsync(
            this.$t('team'),
            this.$store.dispatch('team.add', {data: team}),
            () => {
              this.$navigation.goto({name: 'admin.event.clubs.teams'});
            }
        );
			}
		},
	};
</script>

<style>
</style>
