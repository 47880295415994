<template>
  <div class="c-main">
    <h2 class="c-title c-title--page">{{ $t('session.edit') }}</h2>
    <session :id="$route.params.sessionId" @submit="update($event)" @remove="remove($event)"/>
  </div>
</template>

<script>

import find from 'lodash/find';

export default {
  name: 'session-view',
  components: {
    session: require('./session.vue').default,
  },
  computed: {
    session: function () {
      return find(this.$store.state.sessions.items, (item) => {
        return item.id === this.$route.params.sessionId;
      });
    },
    discipline: function () {
      return this.$store.state.eventDiscipline.discipline;
    }
  },
  methods: {
    update: function (session) {
      session.eventDisciplineId = this.$route.params.disciplineId;
      this.$notify.saveAsync(
        this.$t('session'),
        this.$store.dispatch('session.save', session),
        () => {
          this.$navigation.goto({name: 'admin.event.discipline.sessions'});
        }
      );
    },
    remove: function (session) {
      this.$notify.removeAsync(
        this.$t('session'),
        this.$store.dispatch('session.remove', session),
        () => {
          this.$navigation.goto({name: 'admin.event.discipline.sessions'});
        }
      );
    }
  }
};
</script>

<style>
</style>
