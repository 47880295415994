<template>
  <div class="c-main">
    <form action="#" class="c-main--flex-1">
      <sceTextField v-model="name" name="name" label="name"
                    v-validate data-vv-rules="required" :class="{'is-invalid': errors.has('name')}"/>
      <div class="o-layout">
        <div class="o-layout__item u-1-of-2-at-small">
          <label class="c-input__label" >{{$t('team.members')}}</label>

          <div v-for="(teamMember, index) in teamMembers" class="c-panel__field" :key="teamMember">
            <div class="positions__row_title">
              <span>{{getMemberLabel(teamMember)}}</span>
            </div>
            <div class="positions__row_delete" :id="'remove-position-button-' + index">
              <button v-on:click.prevent="removeMember(teamMember)" class="c-button c-button--ghost">
                ×
              </button>
            </div>
          </div>
        </div>

        <div class="o-layout__item u-1-of-2-at-small">
          <label class="c-input__label" >{{$t("member.add")}}</label>
          <sceSelect v-model="selectClubId" name="selectClub"
                     :options="clubs" v-on:change="setClubMembers()" :config="{localized: false}"/>
          <sceSelect v-model="selectMemberId" name="selectMember"
                     :options="selectClubMembers" v-on:change="selectMember()"
                     :config="{localized: false, emptyLabel: 'member.selectAdd'}">
            <template slot="option" slot-scope="{option}">
              <option :value="option.id">{{ option.name }}</option>
            </template>
          </sceSelect>
        </div>
      </div>

      <sceSelect v-if="id" v-model="newClubId" name="newClub" label="change.club" :options="clubs"
                 :config="{emptyLabel: 'club.remain', localized: false}" />
    </form>

    <footer class="c-footer c-footer--bottom-sticky c-footer--bottom-sticky-fixed-height o-layout">
      <div class="o-layout__item u-1-of-2-at-tiny">
        <sceButton v-if="id" class="c-button--secondary c-button--large" v-on:click="remove()" label="delete" />
      </div>

      <div class="o-layout__item u-1-of-2-at-tiny u-text-right">
        <sceRouteButton :route="{ name: 'admin.event.clubs.teams'}" label="cancel"
                        class="c-button--ghost c-button--large" />
        <sceButton class="c-button--primary c-button--large" v-on:click="submit()" label="save" />
      </div>
    </footer>
  </div>

</template>


<script>
  import memberLib from 'client/lib/member.js';

  import filter from 'lodash/filter';
  import find from 'lodash/find';
  import map from 'lodash/map';
  import sortBy from 'lodash/sortBy';

  export default {
    name: "team",
    props: ['id'],
    data: function () {
      return {
        clubId: this.$route.params.clubId,
        newClubId: undefined,
        name: undefined,
        teamMembers: [],
        externalId: undefined,
        selectClubId: this.$route.params.clubId,
        selectMemberId: undefined,
        selectClubMembers: []
      }
    },
    components: {
    },
    computed: {
      team: function () {
        if (this.id) {
          return find(this.$store.state.teams.items, (item) => item.id === this.id)
        }
        return null
      },
      members: function () {
        return this.$store.state.members.items;
      },
      clubs: function() {
        return sortBy(this.$store.state.clubs.items, 'name');
      },
    },
    mounted: function () {
      this.setTeam();
      this.setClubMembers();
    },
    watch: {
      team: function () {
        this.setTeam();
      }
    },
    methods: {
      setTeam: function () {
        if (this.team) {
          this.name = this.team.name;
          this.externalId = this.team.accessId;

          this.teamMembers = map(this.team.members, item => {
            return item.memberId;
          });
        }
      },
      getMemberLabel: function(memberId) {
        const member = find(this.members, item => {
          return item.id === memberId;
        });
        let label = memberLib.getName(member);

        if (member && member.clubId !== this.clubId) {
          const club = find(this.clubs, item => {
            return item.id === member.clubId;
          });

          label += ' (' + club.name + ')';
        }

        return label;
      },
      setClubMembers: function() {
        this.selectClubMembers = [];
        if (this.selectClubId) {
          this.selectClubMembers = sortBy(map(filter(this.members, item => item.clubId === this.selectClubId),
            item => ({
              id: item.id,
              name: this.getMemberLabel(item.id)
            })), 'name')
        }
        this.selectMemberId = undefined
      },
      selectMember: function() {
        if (this.selectMemberId) {
          this.teamMembers.push(this.selectMemberId);
        }
      },
      removeMember: function(memberId) {
        this.teamMembers = filter(this.teamMembers, item => {
          return item !== memberId;
        });
      },
      submit: function () {
        this.$validator.validateAll().then(() => {
          if (!this.errors.any()) {
            let data = {
              id: this.id,
              clubId: this.clubId,
              name: this.name,
              accessId: this.externalId
            };

            if (this.newClubId) {
              data.clubId = this.newClubId;
            }

            data.members = map(this.teamMembers, (item, key) => {
              return {
                memberId: item,
                id: key,
                index: key
              };
            });
            this.$emit('submit', data);
          } else {
            this.$notify.warning('validation errors');
          }
        });
      },
      remove: function () {
        this.$modal.show({
          title: this.$t('delete.confirm.title'),
          message: this.$t('delete.confirm.text', {item: this.name}),
          onConfirm: () => {
            this.$emit('remove', this.team);
          }
        });
      }
    }
  };
</script>

<style>
</style>
