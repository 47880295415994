<template>
  <div class="c-main">
    <h2 class="c-title c-title--page">{{ $t('categories') }}</h2>
    <grid :config="{searchFields: ['name', 'format'], orderButtons: true}" :data="gridData"
          @switchOrder="switchOrder($event)">
      <template v-slot:gridAdd>
        <sceRouteButton :route="{ name: 'admin.event.discipline.categories.add'}" label="category.add"
                        class="c-button--secondary" icon="svg-symbols.svg#plus-icon"/>
      </template>
      <template v-slot:gridHeader>
        <div class="o-layout c-list__row u-padding-horizontal-small">
          <div class="o-layout__item u-2-of-7-at-medium">
            {{ $t('name') }}
          </div>
          <div class="o-layout__item u-2-of-7-at-medium">
            {{ $t('competitionFormat') }}
          </div>
          <div class="o-layout__item u-1-of-7-at-medium">
            {{ $t('participants.count') }}
          </div>
          <div class="o-layout__item u-1-of-7-at-medium">
            {{ $t('age') }}
          </div>
          <div class="o-layout__item u-1-of-7-at-medium">
            {{ $t('order') }}
          </div>
        </div>
      </template>
      <template v-slot:gridData="{element}">
        <div class="o-layout__item u-2-of-7-at-medium c-list__main-cell">
          <router-link class="c-list__link" :to="{ name: 'admin.event.discipline.categories.view',
           params: {categoryId: element.id}}">
            {{ element.name }}
          </router-link>
        </div>
        <div class="o-layout__item u-2-of-7-at-medium">
          {{ element.format }}
        </div>
        <div class="o-layout__item u-1-of-7-at-medium">
          {{ element.count }}
        </div>
        <div class="o-layout__item u-1-of-7-at-medium">
          {{ element.age }}
        </div>
      </template>
    </grid>
  </div>
</template>

<script>
import filter from 'lodash/filter';
import find from 'lodash/find';
import findIndex from 'lodash/findIndex';
import map from 'lodash/map';
import sortBy from 'lodash/sortBy';
import config from "@/config";

export default {
  name: 'categories-list',
  components: {
    grid: require('client/components/dataGrid.vue').default,
  },
  data: function () {
    return {
      gridData: [],
    };
  },
  computed: {
    categories: function () {
      return sortBy(this.$store.state.categories.items, 'index');
    },
    participations: function () {
      return filter(this.$store.state.participations.items, item => {
        return item.participantType !== 'team';
      });
    },
    formats: function () {
      return this.$store.state.eventDiscipline.formats;
    }
  },
  watch: {
    categories: {
      immediate: true,
      handler: function () {
        this.updateGridData();
      },
    },
    participations: {
      handler: function () {
        this.updateGridData();
      },
    },
  },
  methods: {
    updateGridData: function () {
      this.gridData = map(this.categories, item => {
        return {
          id: item.id,
          name: item.name,
          age: item.minAge + ' - ' + item.maxAge,
          count: this.participationCount(item.id),
          format: this.categoryFormat(item),
        }
      });
    },
    categoryFormat: function (category) {
      const catRound = category.rounds[0];
      const format = catRound ? find(this.formats, item => item.id === catRound.formatId) : null;
      return format ? format.name : '-';
    },
    participationCount: function (categoryId) {
      return filter(this.participations, item => {
        return item.categoryId === categoryId;
      }).length;
    },
    switchOrder: function (event) {
      const index1 = findIndex(this.categories, item => {
        return item.id === event.id;
      });
      let index2 = undefined;
      if (index1 < 0) {
        return;
      }

      if (event.up) {
        if (index1 === 0) {
          return;
        }
        index2 = index1 - 1;
      } else {
        if (index1 === this.categories.length - 1) {
          return;
        }
        index2 = index1 + 1;
      }

      const newIndex1 = this.categories[index2].index;
      const newIndex2 = this.categories[index1].index;
      if (newIndex1 === newIndex2) {
        this.$http.get(config.root + '/eventDisciplines/' + this.sessions[index2].eventDisciplineId + '/fixCategoryIndexes')
          .then(() => {
            setTimeout(() => { this.switchOrder(event) }, 500)
          })
        return
      }

      this.$store.dispatch('category.save', {
        id: this.categories[index1].id,
        eventDisciplineId: this.categories[index1].eventDisciplineId,
        index: newIndex1,
      });
      this.$store.dispatch('category.save', {
        id: this.categories[index2].id,
        eventDisciplineId: this.categories[index2].eventDisciplineId,
        index: newIndex2,
      });
    },
  }
};
</script>

<style>
</style>
