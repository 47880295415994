<template>
  <grid :config="{searchFields: ['name', 'code']}" :data="elements" class="u-1-of-1">
    <template v-slot:gridHeader>
      <div class="o-layout c-list__row u-padding-horizontal-small">
        <div class="o-layout__item u-1-of-1-at-tiny u-1-of-2-at-small">
          {{$t('name')}}
        </div>
        <div class="o-layout__item u-1-of-4-at-medium u-1-of-2-at-tiny">
          {{$t('code')}}
        </div>
        <div class="o-layout__item u-1-of-4-at-medium u-1-of-2-at-tiny">
          {{$t('flag')}}
        </div>
      </div>
    </template>
    <template v-slot:gridData="{element}">
      <div class="o-layout__item u-1-of-1-at-tiny u-1-of-2-at-medium c-list__main-cell">
        <router-link class="c-list__link" :to="{ name: 'admin.event.view', params: { eventId: element.id}}">
          {{element.name}}
        </router-link>
      </div>
      <div class="o-layout__item u-1-of-4-at-medium u-1-of-2-at-tiny">
        {{element.code}}
      </div>
      <div class="o-layout__item u-1-of-4-at-medium u-1-of-2-at-tiny">
        <img class="c-flag" :src="element.flag" />
      </div>
    </template>
  </grid>
</template>

<script>
export default {
  name: "countries",
  components: {
    grid: require('client/components/dataGrid.vue').default,
  },
  computed: {
    elements: function() {
      return this.$store.state.events.countries.map(c => ({
        name: this.$t(c.id),
        code: c.IOC,
        flag: 'images/flags/' + c.id.substr(8,11) + '.svg'
      }))
    }
  },
}
</script>

<style scoped>
.c-flag {
  height: 30px;
}
</style>
