<template>
  <div class="c-main--flex-1">
    <h2 class="c-title c-title--page">{{$t('display.edit')}}</h2>
    <display @submit="update($event)" @remove="remove($event)"></display>
  </div>
</template>

<script>
  export default {
    name: "admin-display-view",
    components: {
      display: require('./display.vue').default,
    },
    methods:{
      update: function(display) {
        this.$notify.saveAsync(
            this.$t('display'),
            this.$store.dispatch('display.update', display),
        );
      },
      remove: function (displayId) {
        this.$notify.removeAsync(
            this.$t('display'),
            this.$store.dispatch('display.remove', {
              displayId: displayId
            }),
            () => {
              this.$navigation.goto({name: 'admin.event.display.list'});
            }
        );
      }
    },
  };
</script>

<style>
</style>
