<template>
	<router-view ></router-view>
</template>

<script>
	export default {
		name:'discipline-wrapper'
	};
</script>

<style scoped>
</style>
