<template>
  <div class="c-main">
    <h3 class="c-title c-title--page-section">{{$t('group.add')}}</h3>
    <group @submit="update($event)" />
  </div>
</template>

<script>
	export default {
		name: "group-add",
    components: {
      group: require('./group.vue').default,
    },
		methods: {
			update: function(group) {
        this.$notify.saveAsync(
            this.$t('group'),
            this.$store.dispatch('group.add', {data: group}),
            () => {
              this.$navigation.goto({name: 'admin.event.clubs.groups'});
            }
        );
			}
		},
	};
</script>

<style>
</style>
