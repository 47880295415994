<template>
  <div class="c-dropdown__wrapper" :class="{'c-button__wrapper--primary': primary}" data-inclusive-menu>
    <button class="c-button c-button--icon c-dropdown__toggler js-menu-trigger"
            :class="{'c-button--large': large, 'c-button--primary': primary, 'c-button--secondary': ! primary}"
            :data-inclusive-menu-opens="menuId" ref="button">
      <span class="c-button__label">{{label}}</span>

      <svg role="img" class="c-button__icon u-margin-left-small">
        <use xlink:href="images/svg-symbols.svg#arrow-right"></use>
      </svg>
    </button>

    <div class="c-dropdown js-dropdown" :id="menuId" data-inclusive-menu-from="right">
      <slot name="content"/>
    </div>
  </div>
</template>

<script>
  import MenuButton from 'inclusive-menu-button';

  export default {
    name: "dropDown",
    props: {
      menuKey: {
        type: String
      },
      label: {
        type: String
      },
      large: {
        type: Boolean
      },
      primary: {
        type: Boolean
      },
    },
    data: function() {
      return {
        menuId: '',
      };
    },
    created: function() {
      this.menuId = 'dropdown-menu-'+ this.menuKey;
    },
    mounted: function() {
      this.activateDropdown();
    },
    methods: {
      activateDropdown: function() {
        // const dropdown = document.querySelector('[data-inclusive-menu-opens]');
        const dropdown = this.$refs.button;
        new MenuButton(dropdown);
      }
    }
  }
</script>

<style scoped>

</style>
