<template>
  <div class="c-main">
    <h2 class="c-title c-title--page">{{$t('syncEvent.select')}}</h2>
    <grid :config="{searchFields: ['title', 'venue']}" :data="events"
          class="c-datagrid--wrapper-home u-1-of-1">
      <template v-slot:gridAdd>
      </template>
      <template v-slot:gridHeader>
        <div class="o-layout c-list__row u-padding-horizontal-small">
          <div class="o-layout__item u-3-of-7-at-medium">
            {{$t('title')}}
          </div>
          <div class="o-layout__item u-1-of-7-at-medium">
            {{$t('venue')}}
          </div>
          <div class="o-layout__item u-2-of-7-at-medium">
            {{$t('date')}}
          </div>
          <div class="o-layout__item u-1-of-7-at-medium">
            &nbsp;
          </div>
        </div>
      </template>
      <template v-slot:gridData="{element}">
        <div class="o-layout__item u-3-of-7-at-medium c-list__main-cell">
          {{element.title}}
        </div>
        <div class="o-layout__item u-1-of-7-at-medium">
          {{element.venue}}
        </div>
        <div class="o-layout__item u-2-of-7-at-medium">
          {{format(element.date_from)}} - {{format(element.date_to)}}
        </div>
        <div class="o-layout__item u-1-of-7-at-medium">
          <router-link :to="{ name: 'admin.sync.event', params: { eventId: element.id}}"
                       class="c-button c-button--primary">
            {{$t("import")}}
          </router-link>
        </div>
      </template>
    </grid>
  </div>
</template>

<script>
  const moment = require('moment');

  import sortBy from 'lodash/sortBy';

  export default {
    name: "sync-list",
    components: {
      grid: require('client/components/dataGrid.vue').default,
    },
    computed: {
      events: function () {
        return sortBy(this.$store.state.importer.events, 'date_from').reverse();
      }
    },
    methods: {
      format: function (date) {
        return moment(date).format('DD/MM/YYYY');
      }
    }
  }
</script>

<style scoped>

</style>
