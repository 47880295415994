<template>
  <grid :config="$options.searchConfig" :data="gridData">
    <template v-slot:gridAdd>
      <router-link exact :to="{ name: 'admin.event.discipline.participants.addTeam'}"
                   class="c-button c-button--secondary c-button--large c-button--icon">
        <svg role="img" class="c-button__icon">
          <use xlink:href="images/svg-symbols.svg#plus-icon"></use>
        </svg>
        <span class="c-button__label">{{ $t("participation.add") }}</span>
      </router-link>
    </template>
    <template v-slot:gridHeader>
      <div class="o-layout c-list__row u-padding-horizontal-small">
        <div class="o-layout__item u-1-of-8-at-medium">
          {{ $t('bib') }}
        </div>
        <div class="o-layout__item u-2-of-8-at-medium">
          {{ $t('name') }}
        </div>
        <div class="o-layout__item u-3-of-8-at-medium">
          {{ $t('club') }}
        </div>
        <div class="o-layout__item u-2-of-8-at-medium">
          {{ $t('category') }}
        </div>
      </div>
    </template>
    <template v-slot:gridData="{element}">
      <div class="o-layout__item u-1-of-8-at-medium c-list__main-cell">
        {{ element.bib }}
      </div>
      <div class="o-layout__item u-2-of-8-at-medium c-list__main-cell">
        <router-link class="c-list__link" :to="{ name: 'admin.event.discipline.participants.viewTeam'
        , params: { participationId: element.id}}">
          {{ element.name }}
        </router-link>
      </div>
      <div class="o-layout__item u-3-of-8-at-medium">
        {{ element.club }}
      </div>
      <div class="o-layout__item u-2-of-8-at-medium">
        {{ element.category }}
      </div>
    </template>
  </grid>
</template>

<script>
//import numeral from "numeral";

import filter from 'lodash/filter';
import find from 'lodash/find';
import map from 'lodash/map';
import sortBy from 'lodash/sortBy';

export default {
  name: 'participations-list',
  components: {
    grid: require('client/components/dataGrid.vue').default,
  },
  searchConfig: {
    searchFields: ['bib', 'name', 'club', 'category'],
    sortFields: ['bib', 'name', 'club', 'category'],
    defaultSortField: 'bib',
  },
  data: function () {
    return {
      gridData: [],
    };
  },
  computed: {
    loaded: function () {
      return this.$store.state.participations.loaded;
    },
    participations: function () {
      return sortBy(filter(this.$store.state.participations.items, item => {
        return item.participantType === 'team';
      }), 'bib');
    },
    teams: function () {
      return this.$store.state.teams.items;
    },
    clubs: function () {
      return this.$store.state.clubs.items;
    },
    categories: function () {
      return this.$store.state.categories.items;
    }
  },
  watch: {
    participations: {
      immediate: true,
      handler: function () {
        this.updateGridData();
      },
    }
  },
  methods: {
    updateGridData: function () {
      const parts = this.participations
      this.gridData = sortBy(map(parts, part => {
        const club = this.getClub(part);
        return {
          id: part.id,
          bib: part.bib,
          club: club ? club.name : '',
          category: this.getCategory(part.categoryId).name,
          name: this.getTeam(part).name,
        }
      }), ['bib'])
    },
    getTeam: function (participation) {
      return find(this.teams, function (team) {
        return team.id === participation.participantId
      });
    },
    getClub: function (participation) {
      const participant = this.getTeam(participation);
      return find(this.clubs, function (club) {
        return club.id === participant.clubId;
      });
    },
    getCategory: function (categoryId) {
      return find(this.categories, function (item) {
        return item.id === categoryId;
      });
    }
  }
};
</script>

<style>
</style>
