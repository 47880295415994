<template>
    <div class="c-main">
      <form style="flex: 1;">
        <h2 class="c-title c-title--page">{{ $t('planningConfig.title') }}</h2>
        <div class="o-layout">
          <div class="o-layout__item">
            <h3 class="c-title c-title--page-section">{{ $t('planningConfig.eventSettings') }}</h3>
            <div class="o-layout">
              <div class="o-layout__item u-1-of-2-at-small u-1-of-3-at-medium">
                <sceMultiSelect v-model="regions" :options="country.regions" name="regions" inputName="regionsField"
                              placeholder="select.regions" label="planning.eventRegions"
                />
              </div>
            </div>
          </div>
        </div>
        <planningConfig v-model="config" :discipline="discipline" />
      </form>
      <footer class="c-footer c-footer--bottom-sticky c-footer--bottom-sticky-fixed-height o-layout">
        <div class="o-layout__item u-text-right">
          <router-link exact :to="{ name: 'admin.event.discipline.sessions'}">
            <button type="button" class="c-button c-button--ghost c-button--large">{{$t("cancel")}}</button>
          </router-link>
          <button type="submit" class="c-button c-button--primary c-button--large" v-on:click.prevent="onSave">
            {{$t('save')}}
          </button>
        </div>
      </footer>
  </div>
</template>

<script>
  import config from 'client/config'

  import cloneDeep from 'lodash/cloneDeep'
  import filter from 'lodash/filter'
  import find from 'lodash/find'
  import includes from 'lodash/includes'
  import map from 'lodash/map'

  export default {
    name: "session-planning-config",
    components: {
      planningConfig: require('client/components/models/planningConfig').default,
    },
    orderOptions: [{
      type: 'club-stick',
    },{
      type: 'club-separate',
    },{
      type: 'category-stick',
    },{
      type: 'region-stick',
    }],
    data: function () {
      return {
        config: {},
        regions: [],
      }
    },
    computed: {
      event: function() {
        return this.$store.state.events.current
      },
      eventDiscipline: function() {
				return find(this.$store.state.eventDisciplines.items, item => item.id === this.$route.params.disciplineId);
			},
      discipline: function() {
        return this.$store.state.eventDiscipline.discipline;
      },
      country: function() {
        return find(this.$store.state.events.countries, c => c.id === this.event.countryId);
      }
    },
    created: function () {
      this.regions = [];
      if (this.eventDiscipline.regions && this.eventDiscipline.regions.length) {
        this.regions = filter(this.country.regions, r => includes(this.eventDiscipline.regions, r.id));
      }
      if (this.eventDiscipline.planningConfig) {
        this.config = cloneDeep(this.eventDiscipline.planningConfig);
      } else {
        this.config = {
          grouping: [],
          groupClubs: 'groupClubs',
          keepDistanceClub: true,
          keepDistancePart: 3,
          warmupConfigs: [],
        };
      }
    },
    watch: {},
    methods: {
      onSave: function() {
        const eventDiscipline = JSON.parse(JSON.stringify(this.eventDiscipline));
        eventDiscipline._rev = undefined;
        eventDiscipline.regions = map(this.regions, r => r.id);
        eventDiscipline.planningConfig = this.config;
        this.$notify.saveAsync(
            this.$t('eventDiscipline.edit'),
            this.$http.patch(config.root + '/eventDisciplines/' + eventDiscipline.id, eventDiscipline),
            () => {
              this.$navigation.goto({name: 'admin.event.discipline.sessions'});
            }
        );
      }
    },
  };
</script>

<style scoped>
</style>
