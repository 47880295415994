<template>
  <div>
    <div :class="{'c-sidebar__active-group': !$route.params.disciplineId}">
      <router-link
        class="c-sidebar__item c-sidebar__item-main c-sidebar__item-with-children"
        v-if="$route.params.disciplineId" exact :to="{ name: 'admin.event.view'}">
        {{ $t('event.general') }}
      </router-link>

      <template v-if="!$route.params.disciplineId">
        <div class="c-sidebar__item c-sidebar__item-main c-sidebar__item-with-children
         c-sidebar__item-open">
          {{ $t('event.general') }}
        </div>
        <router-link
          class="c-sidebar__item"
          v-if="eventId" exact :to="{ name: 'admin.event.view'}">
          {{ $t('settings') }}
        </router-link>
        <router-link
          class="c-sidebar__item"
          :to="{ name: 'admin.event.clubs', params: {}}">
          {{ $t('clubs') }}
        </router-link>
        <router-link class="c-sidebar__item" :to="{ name: 'judge.panels'}" target="_blank">
          {{ $t('judge.console') }}
        </router-link>
        <router-link class="c-sidebar__item" :to="{ name: 'admin.event.qr'}">
          {{ $t('qr.login') }}
        </router-link>
        <router-link class="c-sidebar__item" :to="{ name: 'admin.event.display.list'}">
          {{ $t('displays') }}
        </router-link>
      </template>
    </div>

    <div v-for="discipline in disciplines" :key="discipline.id"
         :class="{'c-sidebar__active-group': ($route.params.disciplineId === discipline.id)}">
      <router-link
        exact class="c-sidebar__item c-sidebar__item-main c-sidebar__item-with-children"
        v-if="$route.params.disciplineId !== discipline.id"
        :to="{ name: 'admin.event.discipline.dummy', params: { disciplineId:discipline.id}}">
        {{ disciplineLabel(discipline) }}
      </router-link>

      <template v-if="$route.params.disciplineId === discipline.id">
        <div class="c-sidebar__item c-sidebar__item-main c-sidebar__item-with-children c-sidebar__item-open">
          {{ disciplineLabel(discipline) }}
        </div>
        <router-link class="c-sidebar__item" :to="{ name: 'admin.event.discipline.dashboard'}">
          {{ $t('dashboard') }}
        </router-link>
        <router-link class="c-sidebar__item" :to="{ name: 'admin.event.discipline.settings'}">
          {{ $t('settings') }}
        </router-link>
        <router-link class="c-sidebar__item" :to="{ name: 'admin.event.discipline.rounds'}">
          {{ $t('rounds') }}
        </router-link>
        <router-link class="c-sidebar__item" :to="{ name: 'admin.event.discipline.categories'}">
          {{ $t('categories') }}
        </router-link>
        <router-link class="c-sidebar__item" :to="{ name: 'admin.event.discipline.participants'}">
          {{ $t('participants') }}
        </router-link>
        <router-link class="c-sidebar__item" :to="{ name: 'admin.event.discipline.sessions'}">
          {{ $t('planning') }}
        </router-link>
        <router-link class="c-sidebar__item" :to="{ name: 'admin.event.discipline.scoreinput'}">
          {{ $t('scoreinput') }}
        </router-link>
        <router-link class="c-sidebar__item" :to="{ name: 'admin.event.discipline.results'}">
          {{ $t('results') }}
        </router-link>
        <router-link class="c-sidebar__item" :to="{ name: 'admin.event.discipline.displayControl'}">
          {{ $t('display.control') }}
        </router-link>
        <router-link class="c-sidebar__item" :to="{ name: 'admin.event.discipline.import'}">
          {{ $t('import') }}
        </router-link>
        <router-link class="c-sidebar__item" :to="{ name: 'admin.event.discipline.export'}">
          {{ $t('export') }}
        </router-link>
      </template>
    </div>

    <!-- <router-link class="c-sidebar__item"	:to="{ name: 'cis.sessions'}">CIS</router-link> -->
    <router-link class="c-sidebar__item u-margin-top-large c-button--icon" v-if="eventId"
                 :to="{ name: 'admin.event.discipline.add'}">
      <svg role="img" class="c-button__icon">
        <use xlink:href="images/svg-symbols.svg#plus-icon"></use>
      </svg>
      <span class="c-button__label">{{ $t('discipline.add') }}</span>
    </router-link>
  </div>
</template>

<script>
export default {
  name: 'event-menu',
  data: function () {
    return {
      eventId: null,
    };
  },
  computed: {
    disciplines: function () {
      return this.$store.state.eventDisciplines.items
    },
  },
  created: function () {
    this.eventId = this.$route.params.eventId;
  },
  methods: {
    disciplineLabel: function (discipline) {
      if (discipline.label) {
        return discipline.label;
      }
      if (discipline.shortName) {
        return discipline.shortName;
      }
      return discipline.name;
    },
  },
};
</script>
