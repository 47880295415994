<template>
  <div class="c-main">
    <form action="#" class="c-main--flex-1">
      <div class="o-layout">
        <div class="o-layout__item u-1-of-2-at-small">
          <sceTextField v-model="lastName" name="lastname" label="lastname"
                        v-validate data-vv-rules="required" :class="{'is-invalid': errors.has('lastname')}"/>
        </div>
        <div class="o-layout__item u-1-of-2-at-small">
          <sceTextField v-model="firstname" name="firstname" label="firstname"
                        v-validate data-vv-rules="required" :class="{'is-invalid': errors.has('firstname')}"/>
        </div>
      </div>
      <div class="o-layout">
        <div class="o-layout__item u-1-of-2-at-small">
          <div class="c-input u-margin-bottom-small" :class="{'is-invalid': errors.has('date-mirror')}">
            <label class="c-input__label" for="dob">{{$t('dob')}}</label>
            <input v-model="dob" type="date" class="c-input__field" id="dob"/>
            <!--<datepicker v-model="dob" id="dob" typeable class="p-birthdate-picker" format="dd/MM/yyyy"
                          :language="$options.dpLanguages[language]" monday-first />-->
          </div>
        </div>

        <div class="o-layout__item u-1-of-2-at-small">
          <sceSelect v-model="gender" name="gender" label="gender" :options="$options.genderOptions"
                     v-validate data-vv-rules="required" :class="{'is-invalid': errors.has('gender')}"/>
        </div>
        <div class="o-layout__item u-1-of-2-at-small">
          <sceTextField v-model="externalId" name="externalId" label="externalId" />
        </div>
        <div class="o-layout__item u-1-of-2-at-small">
          <sceTextField v-model="trainingCenter" name="trainingCenter" label="trainingCenter" />
        </div>
        <div class="o-layout__item u-1-of-2-at-small">
          <sceCheckBox v-model="judge" name="is-judge" label="judge" />
        </div>
      </div>

      <sceSelect v-model="newClubId" name="newClub" label="change.club" :options="clubs"
        :config="{emptyLabel: 'club.remain', localized: false}"/>
    </form>
    <footer class="c-footer c-footer--bottom-sticky c-footer--bottom-sticky-fixed-height o-layout">
      <div class="o-layout__item u-1-of-2-at-tiny">
        <sceButton v-if="id" class="c-button--secondary c-button--large" v-on:click="remove()" label="delete" />
      </div>

      <div class="o-layout__item u-1-of-2-at-tiny u-text-right">
        <sceRouteButton :route="{ name: 'admin.event.clubs.members'}" label="cancel"
                        class="c-button--ghost c-button--large" />
        <sceButton class="c-button--primary c-button--large" v-on:click="submit()" label="save" />
      </div>
    </footer>
  </div>
</template>

<script>
  import find from 'lodash/find';
  import sortBy from 'lodash/sortBy';

  const moment = require('moment');

  import form from 'client/lib/form';

  export default {
    name: "member",
    props: ['id'],
    genderOptions: form.idNamePairs(['male', 'female'], 'gender.'),
    data: function () {
      return {
        newClubId: undefined,
        lastName: undefined,
        firstname: undefined,
        externalId: undefined,
        dob: undefined,
        gender: undefined,
        judge: false,
        trainingCenter: undefined,
      };
    },
    computed: {
      clubs: function() {
        return sortBy(this.$store.state.clubs.items, 'name');
      },
      member: function () {
        if (this.id) {
          return find(this.$store.state.members.items, (item) => {
            return item.id === this.id
          });
        }
        return null;
      },
      language: function() {
        return this.$i18n.locale;
      },
    },
    created: function() {
    },
    mounted: function () {
      this.setMember();
    },
    watch: {
      member: function () {
        this.setMember();
      }
    },
    methods: {
      setMember: function () {
        if (this.member) {
          this.lastName = this.member.lastName;
          this.firstname = this.member.firstname;
          this.externalId = this.member.accessId;
          this.gender = this.member.gender;
          this.dob = this.member.dob ? moment(this.member.dob).format('YYYY-MM-DD') : null;
          this.judge = this.member.judge;
          this.trainingCenter = this.member.trainingCenter;
        }
      },
      submit: function () {
        this.$validator.validateAll().then(() => {
          if (!this.errors.any()) {
            const data = {
              id: this.id,
              clubId: this.newClubId ? this.newClubId : this.$route.params.clubId,
              lastName: this.lastName,
              firstname: this.firstname,
              accessId: this.externalId,
              gender: this.gender,
              dob: this.dob,
              judge: this.judge,
              trainingCenter: this.trainingCenter,
            };

            this.$emit('submit', data);
          } else {
            this.$notify.warning('validation errors');
          }
        });
      },
      remove: function () {
        // check for group or team memberships before deleting a club member
        const groups = this.$store.state.groups.items.filter(g => {
          const gm = g.members.find(m => m.memberId === this.member.id)
          return !! gm
        })
        const teams = this.$store.state.teams.items.filter(t => {
          const tm = t.members.find(m => m.memberId === this.member.id)
          return !! tm
        })

        let message = this.$t('delete.confirm.text', {item: this.lastName + ' ' + this.firstname})

        if (groups.length) {
          message += '<br/><br/>' + this.$t('delete.member.confirm.groups',
            {
              name: this.lastName + ' ' + this.firstname,
              names: groups.map(i => i.name).join(', ')
            })
        }
        if (teams.length) {
          message += '<br/><br/>' + this.$t('delete.member.confirm.teams',
            {
              name: this.lastName + ' ' + this.firstname,
              names: teams.map(i => i.name).join(', ')
            })
        }

        this.$modal.show({
          title: this.$t('delete.confirm.title'),
          message,
          onConfirm: () => {
            this.$emit('remove', { member: this.member, groups, teams })
          }
        })
      }
    }
  };
</script>

<style>
</style>
