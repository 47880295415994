<template>
  <editor api-key="no-api-key" :id="'tinymce-' + id" v-model="content"
          :init="init" :onChange="updateInput()" ref="input" />
</template>

<script>
import Editor from '@tinymce/tinymce-vue';
import 'tinymce/tinymce';
import 'tinymce/themes/silver';
import 'tinymce/icons/default';

import 'tinymce/plugins/table';
import 'tinymce/plugins/lists';
import 'tinymce/plugins/advlist';
import 'tinymce/plugins/link';
import 'tinymce/plugins/lists';
import 'tinymce/plugins/paste';
import 'tinymce/plugins/help';
import 'tinymce/plugins/importcss';

export default {
  name: 'TinyMce',
  components: {
    Editor
  },
  props: ['value', 'id'],
  data() {
    return {
      content: this.value,
      init: {
        emoticons_database_url:
          '/tinymce/plugins/emoticons/js/emojis.min.js',
        skin_url: '/tinymce/skins/ui/oxide',
        content_css: 'styles/app.css',
        height: 320,
        plugins: [
          'lists advlist',
          'link',
          'table',
          'help',
          'paste',
          'importcss'
        ],
        paste_as_text: false,
        toolbar: [
          'undo redo | pastetext | bold italic underline strikethrough | removeformat | formatselect | alignleft aligncenter alignright alignjustify | outdent indent',
          'numlist bullist | link | table tabledelete | tableprops tablerowprops tablecellprops | tableinsertrowbefore tableinsertrowafter tabledeleterow | tableinsertcolbefore tableinsertcolafter tabledeletecol | help'
        ],
        branding: false,
        menubar: false,
      }
    };
  },
  methods: {
    updateInput: function() {
      console.log('change');
      this.$emit('input', this.content);
    }
  },
};
</script>
