<template>
  <div class="c-main">
    <h2 class="c-title c-title--page">{{ $t('participants') }}</h2>

    <div class="c-tabs c-main">
      <div class="c-tab__tab-bar u-margin-bottom-medium" v-if="discipline.team">
        <router-link v-for="tab in $options.tabs" :key="tab.name"
                     class="c-button c-button--large c-button--ghost c-tabs__tab-link" :to="{ name: tab.name}"
                     :exact="tab.exact">
          {{ $t(tab.label) }}
        </router-link>
      </div>
      <div class="c-tabs__panel c-main">
        <router-view></router-view>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "participants-wrapper",
  tabs: [{
    name: 'admin.event.discipline.participants',
    label: 'participants.individual',
    exact: true,
  }, {
    name: 'admin.event.discipline.participantsTeam',
    label: 'participants.teams',
    exact: false,
  }],
  computed: {
    discipline: function () {
      return this.$store.state.eventDiscipline.discipline;
    }
  },
};
</script>

<style scoped>

</style>
