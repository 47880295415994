<template>
  <div>
    <grid :config="{searchFields: ['name'], orderButtons: true}" :data="sessions" @switchOrder="switchOrder($event)">
      <template v-slot:gridAdd>
        <router-link exact :to="{ name: 'admin.event.discipline.sessions.add'}"
                     class="c-button c-button--secondary c-button--icon">
          <svg role="img" class="c-button__icon">
            <use xlink:href="images/svg-symbols.svg#plus-icon"></use>
          </svg>
          <span class="c-button__label">{{ $t("session.add") }}</span>
        </router-link>
      </template>
      <template v-slot:gridHeader>
        <div class="o-layout c-list__row u-padding-horizontal-small">
          <div class="o-layout__item u-2-of-10-at-medium u-1-of-1">
            {{ $t('name') }}
          </div>
          <div class="o-layout__item u-2-of-10-at-medium u-1-of-2">
            {{ $t('round') }}
          </div>
          <div class="o-layout__item u-2-of-10-at-medium u-1-of-2">
            {{ $t('date') }}
          </div>
          <div class="o-layout__item u-3-of-10-at-medium u-1-of-6">
            &nbsp;
          </div>
          <div class="o-layout__item u-1-of-10-at-medium u-1-of-6">
            &nbsp;
          </div>
        </div>
      </template>
      <template v-slot:gridData="{element}">
        <div class="o-layout__item u-2-of-10-at-medium u-5-of-6 c-list__main-cell">
          <router-link class="c-list__link" :to="{ name: 'admin.event.discipline.sessions.view',
             params: {sessionId: element.id}}">
            {{ element.name }}
          </router-link>
        </div>
        <div class="o-layout__item u-2-of-10-at-medium u-1-of-2">
          {{ getRound(element.roundId).name }}
        </div>
        <div class="o-layout__item u-2-of-10-at-medium u-1-of-2">
          {{ element.date.substr(0, 10) }}
        </div>
        <div class="o-layout__item u-3-of-10-at-medium u-1-of-1">
          <sceRouteButton label="order" class="c-button--secondary c-button--small"
                :route="{ name: getOrderLinkName(element.id), params: {sessionId: element.id, set: 1}}" />
          <sceRouteButton label="panels" class="c-button--secondary c-button--small"
                :route="{ name: 'admin.event.discipline.sessions.panels', params: {sessionId: element.id}}" />

        </div>
      </template>
    </grid>
  </div>
</template>

<script>
import find from 'lodash/find';
import findIndex from 'lodash/findIndex';
import sortBy from 'lodash/sortBy';

import config from 'client/config'

import sessionLib from 'client/lib/session'

export default {
  name: 'sessions-list',
  props: ['event'],
  components: {
    grid: require('client/components/dataGrid.vue').default,
  },
  computed: {
    sessions: function () {
      return sortBy(this.$store.state.sessions.items, 'index');
    },
    rounds: function () {
      return this.$store.state.eventDiscipline.rounds
    },
    discipline: function () {
      return this.$store.state.eventDiscipline.discipline;
    },
  },
  methods: {
    getRound: function (roundId) {
      return find(this.rounds, function (item) {
        return item.id === roundId
      });
    },
    getOrderLinkName: function(sessionId) {
      const rotationType = sessionLib.getSessionRotationType(sessionId)
      switch (rotationType) {
        case 'mixed':
          return 'admin.event.discipline.sessions.order.mixed'
        default:
          return 'admin.event.discipline.sessions.order.set'
      }
    },
    switchOrder: function (event) {
      const index1 = findIndex(this.sessions, item => {
        return item.id === event.id;
      });
      let index2 = undefined;
      if (index1 < 0) {
        return;
      }

      if (event.up) {
        if (index1 === 0) {
          return;
        }
        index2 = index1 - 1;
      } else {
        if (index1 === this.sessions.length - 1) {
          return;
        }
        index2 = index1 + 1;
      }

      const newIndex1 = this.sessions[index2].index;
      const newIndex2 = this.sessions[index1].index;
      if (newIndex1 === newIndex2) {
        this.$http.get(config.root + '/eventDisciplines/' + this.sessions[index2].eventDisciplineId + '/fixSessionIndexes')
          .then(() => {
            setTimeout(() => { this.switchOrder(event) }, 500)
          })
        return
      }
      this.$store.dispatch('session.save', {
        id: this.sessions[index1].id,
        eventDisciplineId: this.sessions[index1].eventDisciplineId,
        index: newIndex1,
      });
      this.$store.dispatch('session.save', {
        id: this.sessions[index2].id,
        eventDisciplineId: this.sessions[index2].eventDisciplineId,
        index: newIndex2,
      });
    },
  }
};
</script>

<style>
</style>
