<template>
  <keep-alive include="members-list">
    <router-view :key="'members-'+$route.fullPath"></router-view>
  </keep-alive>
</template>

<script>
  export default {
    name: "member-wrapper",
  };
</script>

<style>
</style>
