<template>
  <div class="">
    <h2 class="c-title c-title--page">{{$t('planning.schedule.checkOrder')}}</h2>
    <p v-if="! loaded">Loading schedules</p>
    <div v-else>
      <div v-for="session of clubSchedule" :key="session.index"
           class="u-margin-bottom-medium">
        <h3 class="c-title c-title--page-section">{{$t('session')}} {{ session.index+1 }}</h3>
        <div v-if="! session.processed.length">
          No overlaps found
        </div>
        <template v-for="item of session.processed">
          <template v-if="item.flag">
            <div v-for="rotation in item.rotations" :key="item.participantId + '-' + item.categoryName + '|' + rotation.rotation"
                 class="o-layout u-margin-bottom-medium">
              <div class="o-layout__item u-3-of-10">
                {{item.participantName}}<br/>
                {{ item.clubName }}
              </div>
              <div class="o-layout__item u-3-of-10">
                {{item.categoryName}}<br/>
              </div>
              <div class="o-layout__item u-1-of-10">
                {{ $t('rotation')}} {{ (rotation.rotation + 1) }}
              </div>
              <div class="o-layout__item u-3-of-10">
                <div v-for="session of rotation.items" :key="session.eventDisciplineId">
                  {{getEventDisciplineName(session.eventDisciplineId)}} - {{ session.sessionName }}
                </div>
              </div>
            </div>
          </template>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import config from 'client/config'

import memberLib from 'client/lib/member'

export default {
  name: "checkOrder",
  data: function() {
    return {
      loaded: false,
      clubSchedule: []
    }
  },
  computed: {
    event: function () {
      return this.$store.state.events.current
    },
    eventDisciplines: function () {
      return this.$store.state.eventDisciplines.items
    },
    clubs: function() {
      return this.$store.state.clubs.items
    },
    members: function() {
      return this.$store.state.members.items
    }
  },
  created: function() {
    this.$http.get(
      config.root + '/events/' + this.event.id + '/clubSchedule')
      .then(response => {
        this.clubSchedule = response.data
        this.filterItems()
        this.loaded = true
      });
  },
  methods: {
    filterItems: function() {
      this.clubSchedule.forEach(session => {
        // create array mapping club/categories to rotations:
        const clubCategories = []
        session.items.forEach(item => {
          let cc = clubCategories.find(i => i.participantId === item.participantId)
          if (! cc) {
            cc = {
              categoryName: item.categoryName,
              participantId: item.participantId,
              clubId: item.clubId,
              participantName: this.getParticipantName(item.participantId),
              clubName: this.getClubName(item.clubId),
              rotations: [],
              flag: false,
            }
            clubCategories.push(cc)
          }

          let rotation = cc.rotations.find(r => r.rotation === item.rotation)

          if (! rotation) {
            rotation = {
              rotation: item.rotation,
              items: [],
            }
            cc.rotations.push(rotation)
          }

          rotation.items.push({
            eventDisciplineId: item.eventDisciplineId,
            sessionName: item.sessionName,
          })
          if (rotation.items.length > 1) {
            cc.flag = true
          }
        })

        session.processed = clubCategories
      })
    },
    getParticipantName: function(participantId) {
      const member = this.members.find(m => m.id === participantId)

      return memberLib.getName(member)
    },
    getClubName: function(clubId) {
      const club = this.clubs.find(c => c.id === clubId)
      if (club) return club.name
      return ''
    },
    getEventDisciplineName: function(edId) {
      const ed = this.eventDisciplines.find(i => i.id === edId)
      if (ed) return ed.label ? ed.label : ed.name
      return ''
    },
  },
}
</script>

<style scoped>

</style>
