<template>
  <keep-alive>
    <router-view :key="'clubs' + $route.params.clubId ? $route.params.clubId : 'list'"></router-view>
  </keep-alive>
</template>

<script>
export default {
  name: "clubs-wrapper",
};
</script>

<style>
</style>
