<template>
  <grid v-if="isAdmin" :config="{searchFields: ['title', 'venue', 'date', 'user']}" :data="eventData" class="u-1-of-1">
    <template v-slot:gridAdd>
      <router-link v-if="isLocal" class="c-button c-button--secondary c-button--large c-button--icon"
                   exact :to="{ name: 'admin.sync.list'}">
        <svg role="img" class="c-button__icon">
          <use xlink:href="images/svg-symbols.svg#plus-icon"></use>
        </svg>
        <span class="c-button__label">{{$t("importer.event")}}</span>
      </router-link>
      <router-link v-else class="c-button c-button--secondary c-button--large c-button--icon"
                   exact :to="{ name: 'admin.eventAdd'}">
        <svg role="img" class="c-button__icon">
          <use xlink:href="images/svg-symbols.svg#plus-icon"></use>
        </svg>
        <span class="c-button__label">{{$t("event.add")}}</span>
      </router-link>
    </template>
    <template v-slot:gridHeader>
      <div class="o-layout c-list__row u-padding-horizontal-small">
        <div class="o-layout__item u-3-of-8-at-medium">
          {{$t('title')}}
        </div>
        <div class="o-layout__item u-1-of-8-at-medium u-1-of-2-at-tiny">
          {{$t('venue')}}
        </div>
        <div class="o-layout__item u-1-of-4-at-medium u-1-of-2-at-tiny">
          {{$t('date')}}
        </div>
        <div class="o-layout__item u-1-of-4-at-medium u-1-of-2-at-tiny">
          {{$t('user')}}
        </div>
      </div>
    </template>
    <template v-slot:gridData="{element}">
      <div class="o-layout__item u-3-of-8-at-medium c-list__main-cell">
        <router-link class="c-list__link" :to="{ name: 'admin.event.view', params: { eventId: element.id}}">
          {{element.title}}
        </router-link>
      </div>
      <div class="o-layout__item u-1-of-8-at-medium u-1-of-2-at-tiny">
        {{element.venue}}
      </div>
      <div class="o-layout__item u-1-of-4-at-medium u-1-of-2-at-tiny">
        {{element.date}}
      </div>
      <div class="o-layout__item u-1-of-4-at-medium u-1-of-1-at-tiny">
        {{element.user}}
      </div>
    </template>
  </grid>
  <grid v-else :config="{searchFields: ['title', 'venue', 'date']}" :data="eventData" class="u-1-of-1">
    <template v-slot:gridAdd>
      <router-link v-if="isLocal" class="c-button c-button--secondary c-button--large c-button--icon"
                   exact :to="{ name: 'admin.sync.list'}">
        <svg role="img" class="c-button__icon">
          <use xlink:href="images/svg-symbols.svg#plus-icon"></use>
        </svg>
        <span class="c-button__label">{{$t("importer.event")}}</span>
      </router-link>
      <router-link v-else class="c-button c-button--secondary c-button--large c-button--icon"
                   exact :to="{ name: 'admin.eventAdd'}">
        <svg role="img" class="c-button__icon">
          <use xlink:href="images/svg-symbols.svg#plus-icon"></use>
        </svg>
        <span class="c-button__label">{{$t("event.add")}}</span>
      </router-link>
    </template>
    <template v-slot:gridHeader>
      <div class="o-layout c-list__row u-padding-horizontal-small">
        <div class="o-layout__item u-3-of-6-at-medium">
          {{$t('title')}}
        </div>
        <div class="o-layout__item u-1-of-6-at-medium u-1-of-2-at-tiny">
          {{$t('venue')}}
        </div>
        <div class="o-layout__item u-2-of-6-at-medium u-1-of-2-at-tiny">
          {{$t('date')}}
        </div>
      </div>
    </template>
    <template v-slot:gridData="{element}">
      <div class="o-layout__item u-3-of-6-at-medium c-list__main-cell">
        <router-link class="c-list__link" :to="{ name: 'admin.event.view', params: { eventId: element.id}}">
          {{element.title}}
        </router-link>
      </div>
      <div class="o-layout__item u-1-of-6-at-medium u-1-of-2-at-tiny">
        {{element.venue}}
      </div>
      <div class="o-layout__item u-2-of-6-at-medium u-1-of-2-at-tiny">
        {{element.date}}
      </div>
    </template>
  </grid>
</template>
<script>
  const moment = require('moment');
  import find from 'lodash/find';
  import map from 'lodash/map';
  import sortBy from 'lodash/sortBy';
  import userLib from 'client/lib/user';

  export default {
    name:'eventsList',
    components: {
      grid: require('client/components/dataGrid.vue').default,
    },
    data: function() {
      return {
        isAdmin: false,
        isLocal: false,
        eventData: [],
      }
    },
    computed: {
      events: function() {
        return sortBy(this.$store.state.events.items, ['date_from', 'title']).reverse();
      },
      users: function() {
        return this.$store.state.users.items;
      },
    },
    created: function() {
      this.init();
    },
    watch: {
      events: function() {
        this.init();
      },
    },
    methods: {
      init: function() {
        this.isAdmin = userLib.checkRole('admin');
        this.isLocal = this.$store.state.environment.local;
        this.eventData = map(this.events, item => {
          let event = {
            id: item.id,
            title: item.title,
            venue: item.venue,
            date: this.format(item.date_from) + ' - ' + this.format(item.date_to),
          };
          if (this.isAdmin) {
            const user = find(this.users, user => {
              return item.owner_id === user.id;
            });
            if (user) {
              if (user.name) {
                event.user = user.name;
              } else {
                event.user = user.username;
              }
            } else {
              event.user = 'missing';
            }
          }
          return event;
        });
      },
      format: function(date){
        return moment(date).format('DD/MM/YYYY');
      },
    }
  };
</script>
