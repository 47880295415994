<template>
  <div class="">
    <h2 class="c-title c-title--page">{{$t('planning.schedule.bibAssignment')}}</h2>
    <div class="o-layout" v-if="discipline.team">
      <div class="o-layout__item">
        <h4>{{$t('bibAssignment.groupTeams')}}</h4>
      </div>

      <div class="o-layout__item">
        <table class="o-table o-table--fixed c-table--results">
          <thead>
            <tr>
              <th class="c-table-cell__valign-bottom c-table-text__regular">
                {{$t('select')}}<br/>
                <a href="#" v-on:click.prevent="select(true)" class="">
                  {{$t('all')}}
                </a>&nbsp;
                <a href="#" v-on:click.prevent="select(false)" class="">
                  {{$t('none')}}
                </a>
              </th>
              <th v-for="set in maxSets" :key="'session-' + set">
                {{ $t('set')}} {{ set }}
              </th>
            </tr>
          </thead>
          <tbody class="c-table__body">
            <template v-for="day of sessionDates">
              <tr v-for="session of day.sessions" :key="session.id">
                <td class="c-table__grouped-cells">
                  <div class="c-table__cell-content">
                    {{ getDateStr(day.date) }} {{session.name}}
                  </div>
                </td>
                <td class="c-table__grouped-cells"
                    v-for="set in maxSets" :key="'sessionSet-' + set">
                  <div class="c-table__cell-content">
                    <div class="c-input" v-if="session.sets >= set" >
                      <input v-model="groupTeams[session.id][set-1]"
                             :id="'sessionSet-' + session.id + '-' + set"
                             :name="'sessionSet-' + session.id + '-' + set"
                             type="checkbox" class="c-input__field c-input__field-checkbox" />
                      <label :for="'sessionSet-' + session.id + '-' + set" class="c-input__label c-input__label-checkbox">
                      </label>
                    </div>
                  </div>
                </td>
              </tr>
            </template>
          </tbody>
        </table>
      </div>
    </div>
    <div class="o-layout">
      <div class="o-layout__item u-1-of-2">
        <sceNumberField v-model="spacing" name="spacing" label="bibAssignment.spacing" min="0" max="100" step="1"
                        v-validate data-rules="required|numeric|min_value:0|max_value:100"
                        :class="{'is-invalid': errors.has('spacing')}" />
      </div>
      <div class="o-layout__item u-1-of-2">
        <sceCheckBox v-model="keepNumbers" name="keepNumbers" label="bibAssignment.keepNumbers" :disabled="groupTeams.length > 0"/>
      </div>
    </div>
    <div class="o-layout">
      <div class="o-layout__item">
        <sceRouteButton :route="{ name: 'admin.event.discipline.sessions'}" label="cancel"
                        class="c-button--ghost c-button--large" />
        <sceButton class="c-button--primary c-button--large" v-on:click="execute()" label="bibAssignment.execute" />
      </div>
    </div>
  </div>
</template>

<script>

import planningLib from 'client/lib/planning'

import map from "lodash/map";
import sortBy from "lodash/sortBy";

import moment from "moment";

export default {
  name: "bibAssignment",
  data: () => {
    return {
      sessionDates: [],
      keepNumbers: true,
      spacing: 5,
      groupTeams: {},
      maxSets: 1,
    };
  },
  computed: {
    event: function () {
      return this.$store.state.events.current
    },
    discipline: function () {
      return this.$store.state.eventDiscipline.discipline;
    },
    sessions: function () {
      return sortBy(this.$store.state.sessions.items, 'index');
    },
  },
  created: function() {
    this.selection = this.sessions.map(s => s.id)
    this.initSessionData()
  },
  methods: {
    initSessionData: function() {
      const days = planningLib.getEventDays(this.event);
      let maxSets = 1
      const sessionDates = map(days, (day) => {
        const sessions = planningLib.getSessionsByDate(day, this.sessions);
        sessions.forEach(session => {
          this.$set(this.groupTeams, session.id,  Array.from(Array(session.sets), () => false))
          maxSets = Math.max(maxSets, session.sets)
        })
        this.maxSets = maxSets

        return {
          date: day,
          sessions: sessions,
        }
      })
      this.sessionDates = sessionDates
    },
    getDateStr: function (date) {
      return moment(date).format("DD-MM-YYYY")
    },
    select: function(state) {
      Object.keys(this.groupTeams).forEach(key => {
        for (let s=0; s < this.groupTeams[key].length; s++) {
          // console.log(key, s)
          this.$set(this.groupTeams[key], s, state)
        }
      })
    },
    execute: function() {
      planningLib.bibAssignment(this.spacing, this.keepNumbers, this.groupTeams)
    },
  }
}
</script>

<style scoped>

</style>
