<template>
  <div class="c-main">
    <form action="#" class="c-main--flex-1 u-margin-bottom-small">
      <div class="o-layout" v-if="id">
        <div class="o-layout__item">
          <h4><span class="name" v-for="name in participantnames" :key="name">{{name}}, </span></h4>
        </div>
        <div class="o-layout__item">
          <p>{{clubname}}</p>
        </div>
      </div>
      <div class="o-layout" v-else>
        <div class="o-layout__item u-1-of-2-at-small">
          <sceSelect v-model="selectClubId" name="selectClub" label="club.selectFrom"
                     :options="clubs" v-on:change="setClubMembers()" :config="{localized: false}"/>
        </div>
        <div class="o-layout__item u-1-of-2-at-small">
          <sceSelect v-if="participantType==='clubMember'" v-model="participantId" name="selectMember"
                     label="member.selectAdd" :options="selectClubMembers" :config="{emptyLabel: 'none'}"
                     v-validate data-vv-rules="required" :class="{'is-invalid': errors.has('selectMember')}">
            <template v-slot:option="{option}">
              <option :value="option.id">
                {{option.lastName}} {{option.firstname}}
              </option>
            </template>
          </sceSelect>
          <sceSelect v-if="participantType==='group'" v-model="participantId" name="selectGroup"
                     label="group.selectAdd" :options="selectClubGroups" :config="{localized: false, emptyLabel: 'none'}"
                     v-validate data-vv-rules="required" :class="{'is-invalid': errors.has('selectGroup')}" />
          <sceSelect v-if="participantType==='team'" v-model="participantId" name="selectTeam"
                     label="team.selectAdd" :options="selectClubTeams" :config="{localized: false, emptyLabel: 'none'}"
                     v-validate data-vv-rules="required" :class="{'is-invalid': errors.has('selectTeam')}" />
        </div>
      </div>

      <div class="o-layout">
        <div class="o-layout__item u-1-of-3-at-small">
          <sceSelect v-model="categoryId" name="category" label="category"
                     :options="categories" :config="{localized: false}"
                     v-validate data-vv-rules="required" :class="{'is-invalid': errors.has('category')}"/>
        </div>
        <div class="o-layout__item u-1-of-3-at-small">
          <sceNumberField v-model.number="bib" name="bib" label="bib" min="0" max="9999" step="1"
                          v-validate data-vv-rules="numeric|min_value:0|max_value:9999"/>
        </div>
        <div class="o-layout__item u-1-of-3-at-small">
          <sceTextField v-model="externalId" name="externalId" label="externalId" />
        </div>
        <div class="o-layout__item u-1-of-3-at-small">
          <sceCheckBox v-model="guest" name="guest" label="guest" />
        </div>

        <div class="o-layout__item u-1-of-3-at-small" v-for="field of customFields" :key="field.config.label">
          <div v-if="field.config.inputType !== 'copy'" :is="'custom-field-' + field.config.inputType"
               v-model="field.value" :name="'customField-' + field.config.label" :config="field.config"/>
        </div>
      </div>

      <template v-if="category">
        <div v-for="(round, round_i) in getRounds()" :key="round.roundId" class="o-layout">
          <div class="o-layout__item">
            <h4 class="c-title c-title--page-section u-margin-top-large">{{round.roundName}}</h4>
          </div>
          <div class="o-layout__item u-1-of-3-at-small">
            <sceSelect v-model="round.status" :name="'status-'+round_i" label="status" :options="$options.statusOptions"
                       v-validate data-vv-rules="required" :class="{'is-invalid': errors.has('status-'+round_i)}"/>
          </div>
          <div class="o-layout__item u-1-of-3-at-small">
            <sceSelect v-model.number="round.subDivision" :name="'subDivision-'+round_i" label="subDivision"
                       :options="getRange(round.subDivisions)" :config="{optionLabel: 'id', localized: false}"
                       v-validate data-vv-rules="required" :class="{'is-invalid': errors.has('subDivision-'+round_i)}" />
          </div>
          <div v-if="participantType === 'team'" class="o-layout__item u-1-of-3-at-small">
            <sceNumberField v-model.number="round.deductions" :name="'deductions-'+round_i" label="deductions"
                            min="0" max="99" step="0.1" v-validate data-vv-rules="min_value:0|max_value:99|decimal:1"/>
          </div>
          <template  v-if="discipline.events">
            <div class="o-layout__item">
              <h5 class="c-title c-title--page-section">{{$t('session.exercises')}}</h5>
            </div>

            <div class="o-layout__item u-1-of-3-at-small"
                 v-for="exerciseType in round.exerciseTypes" :key="exerciseType.exerciseTypeId">
              <sceCheckBox v-model="exerciseType.selected" :name="round.roundId+'-exerciseType-' + exerciseType.exerciseTypeId"
                           :label="'exercise.type.' + exerciseType.exerciseTypeId" />
              <template v-if="exerciseType.selected">
              <sceSelect v-for="option in exerciseType.options"
                         v-model="exerciseType.optionValues[option.id]"
                         :name="'option-'+exerciseType.exerciseTypeId+'-'+ option.id"
                         :key="'option-'+exerciseType.exerciseTypeId+'-'+ option.id"
                         :options="option.options">
              </sceSelect>
              </template>
            </div>
          </template>
        </div>
      </template>

    </form>
    <footer class="c-footer c-footer--bottom-sticky c-footer--bottom-sticky-fixed-height o-layout">
      <div class="o-layout__item u-1-of-2-at-tiny">
        <sceButton v-if="id" class="c-button--secondary c-button--large" v-on:click="remove()" label="delete" />
      </div>

      <div class="o-layout__item u-1-of-2-at-tiny u-text-right">
        <sceButton class="c-button--ghost c-button--large" v-on:click="$emit('cancel')" label="cancel" />
        <sceButton class="c-button--primary c-button--large" v-on:click="submit()" label="save" />
      </div>
    </footer>
  </div>
</template>

<script>
  import clone from 'lodash/clone'
  import filter from 'lodash/filter'
  import find from 'lodash/find'
  import forEach from 'lodash/forEach'
  import map from 'lodash/map'
  import range from 'lodash/range'
  import sortBy from 'lodash/sortBy'

  import categoryLib from 'client/lib/category'
  import customFieldsLib from 'client/lib/customFields'
  import participantLib from 'client/lib/participant.js'
  import options from "@/lib/options";

  export default {
    name: "participation",
    ...options.participation,
    props: ['id', 'participantType'],
    data: function () {
      return {
        clubname: undefined,
        participantnames: undefined,
        selectClubId: null,
        selectClubMembers: [],
        selectClubGroups: [],
        selectClubTeams: [],
        participantId: undefined,
        categoryId: undefined,
        category: undefined,
        subDivision: 1,
        bib: undefined,
        status: 'present',
        guest: false,
        externalId: undefined,
        participationRounds: [],
        customFields: [],
      }
    },
    computed: {
      members: function () {
        return this.$store.state.members.items;
      },
      groups: function () {
        return this.$store.state.groups.items;
      },
      teams: function () {
        return this.$store.state.teams.items;
      },
      clubs: function() {
        return sortBy(this.$store.state.clubs.items, 'name');
      },
      participation: function () {
        return find(this.$store.state.participations.items, (item) => {
          return item.id === this.id
        });
      },
      categories: function() {
        return sortBy(this.$store.state.categories.items, 'index');
      },
      rounds: function() {
        return this.$store.state.eventDiscipline.rounds;
      },
      discipline: function() {
        return this.$store.state.eventDiscipline.discipline;
      },
      exerciseTypes: function() {
        return sortBy(this.$store.state.eventDiscipline.exerciseTypes, 'index');
      }
    },
    mounted: function () {
      this.setParticipation();
    },
    watch: {
      participation: function() {
        this.setParticipation();
      },
      categoryId: function() {
        this.setCategory();
      },
    },
    methods: {
      getRounds: function() {
        return filter(this.participationRounds, r => this.categoryHasRound(r.roundId));
      },
      categoryHasRound: function(roundId) {
        const catRound = find(this.category.rounds, item => item.roundId === roundId)

        return catRound && catRound.formatId
      },
      setParticipation: function() {
        if (! this.participation) {
          return
        }

        this.participantnames = participantLib.getParticipantNames(this.participation)
        const participant = participantLib.getParticipant(this.participation)
        const club = find(this.$store.state.clubs.items, item => item.id === participant.clubId)
        this.clubname = club.name

        this.id = this.participation.id
        this.participantId = this.participation.participantId
        this.categoryId = this.participation.categoryId
        this.bib = this.participation.bib
        this.status = this.participation.status
        this.guest = this.participation.guest
        this.externalId = this.participation.accessId

        if (this.discipline.partFields?.length) {
          this.customFields = customFieldsLib.getFields(this.discipline.partFields, this.participation.customValues || {})
        }

        this.setCategory()
      },
      setClubMembers: function() {
        this.selectClubMembers = [];
        this.selectClubTeams = [];
        if (this.selectClubId) {
          switch (this.participantType) {
            case 'clubMember':
              this.selectClubMembers = sortBy(filter(this.members, item => {
                return item.clubId === this.selectClubId;
              }), ['lastName', 'firstname']);
              break;

            case 'group':
              this.selectClubGroups = sortBy(filter(this.groups, item => {
                return item.clubId === this.selectClubId;
              }), 'name');
              break;

            case 'team':
              this.selectClubTeams = sortBy(filter(this.teams, item => {
                return item.clubId === this.selectClubId;
              }), 'name');
              break;
          }
          this.participantId = null;
        }
      },
      setCategory: function() {
        if (this.categoryId) {
          this.category = find(this.categories, item => {
            return item.id === this.categoryId;
          });

          let rounds = [];
          forEach(this.category.rounds, item => {
            const round = find(this.rounds, r => r.id === item.roundId)
            if (round === undefined || ! item.formatId) {
              return
            }

            let partRound = undefined;
            if (this.participation && this.participation.rounds) {
              partRound = find(this.participation.rounds, item => item.roundId === round.id)
            }

            const exerciseTypes = this.getRoundExerciseTypes(round)
            if (partRound && partRound.exerciseSelection) {
              forEach(exerciseTypes, exerciseType => {
                const selection = find(partRound.exerciseSelection,
                  item => item.exerciseTypeId === exerciseType.exerciseTypeId)

                if (selection) {
                  exerciseType.selected = true
                  let options = clone(selection.options)
                  if (Array.isArray(options)) options = {}
                  exerciseType.optionValues = options || {}
                } else {
                  exerciseType.selected = false;
                  exerciseType.optionValues = {}
                }
              });
            }

            rounds.push({
              roundId: round.id,
              roundName: round.name,
              subDivisions: item.subDivisions,
              status: partRound ? partRound.status : round.index === 0 ? 'present' : 'not-selected',
              subDivision: partRound ? partRound.subDivision : 1,
              exerciseTypes: exerciseTypes,
              deductions: partRound?.deductions !== undefined ? partRound.deductions : null
            })
          })
          this.participationRounds = rounds
        }
        else {
          this.category = undefined
          this.rounds = []
        }
      },
      getRoundExerciseTypes: function(round) {
        const exercises = categoryLib.getCategoryExerciseTypes(this.category.id, round.id)

        return map(exercises, exercise => {
          const config = categoryLib.getConfiguration(this.category.id, round.id, exercise)
          return {
            exerciseTypeId: exercise,
            options: this.getExerciseTypeOptions(round, config),
            optionValues: {},
          }
        })
      },
      getRange: function(count) {
        return map(range(1, count +1), x => {return {id: x}})
      },
      getExerciseTypeOptions: function(round, config) {
        const options = config.options || []
        if (round.index > 0 && round.mainRankingType === 'EVENTS') {
          options.push('status')
        }
        const result = options.map(option => {
          const optionData = {
            id: option
          }

          switch (option) {
            case 'exerciseTypeId':
              optionData.options = this.exerciseTypes.map(et => ({
                id: et.id,
                name: 'exercise.type.' + et.id,
              }))
              break
            case 'status':
              optionData.options = ['present', 'reserve'].map(s => ({
                id: s,
                name: 'participation.status.' + s,
              }))
              break
          }
          return optionData
        })
        return result
      },
      submit: function () {
        this.$validator.validateAll().then(() => {
          if (!this.errors.any()) {
            let data = {
              id: this.id,
              participantType: this.participantType,
              participantId: this.participantId,
              categoryId: this.categoryId,
              bib: this.bib,
              status: this.status,
              guest: this.guest,
              accessId: this.externalId
            }

            const customValues = {}
            this.customFields.forEach(field => {
              if (field.value !== undefined) {
                customValues[field.config.label] = field.value
              }
            })
            data.customValues = customValues

            let rounds = []
            let count = 0
            forEach(this.participationRounds, item => {
              if (item.status !== 'not-selected') {
                let exerciseSelection = [];
                forEach(item.exerciseTypes, exerciseType => {
                  if (exerciseType.selected) {
                    console.log(exerciseType)
                    exerciseSelection.push({
                      exerciseTypeId: exerciseType.exerciseTypeId,
                      options: exerciseType.optionValues,
                    })
                  }
                })

                rounds.push({
                  roundId: item.roundId,
                  status: item.status,
                  subDivision: item.subDivision,
                  deductions: item.deductions,
                  id: count++,
                  exerciseSelection,
                })
              }
            })
            data.rounds = rounds

            this.$emit('submit', data)
          } else {
            this.$notify.warning('Validation errors')
          }
        });
      },
      remove: function () {
        this.$modal.show({
          title: this.$t('delete.confirm.title'),
          message: this.$t('delete.confirm.text', {item: this.$t('this.participation')}),
          onConfirm: () => {
            this.$emit('remove', this.id)
          }
        })
      }
    }
  };
</script>

<style scoped>
</style>
