<template>
  <div class="u-margin-bottom-medium">
    <h3 class="c-title c-title--page-section">{{ $t('round') }} {{ round.index + 1 }}: {{ round.name }}</h3>
    <form action="#">
      <div class="o-layout">
        <div class="o-layout__item">
          <div class="c-input" :class="{'is-invalid': errors.has('name')}">
            <label class="c-input__label" for="name">{{ $t('name') }}</label>
            <input v-model="name" class="c-input__field" name="name" type="text" id="name"
                   v-validate data-vv-rules="required">
          </div>
        </div>
        <template v-for="rank in rankings">
          <div class="o-layout__item u-1-of-3-at-small" :key="rank.rankingType+'label'">
            <div class="c-input">
              <input v-model="rank.enabled" :id="rank.rankingType + '-' + round.index" type="checkbox"
                     class="c-input__field c-input__field-checkbox"/>
              <label :for="rank.rankingType + '-' + round.index"
                     class="c-input__label c-input__label-checkbox">
                {{ $t('rankingType.' + rank.rankingType) }}
              </label>
            </div>
          </div>
          <div class="o-layout__item u-1-of-3-at-small" :key="rank.rankingType+'result'">
            <div class="c-input">
              <select class="c-input__field c-input__field-select" v-model="rank.nextRound">
                <option value="">{{ $t('round.final') }}</option>
                <option :value="round.id" v-for="round in rounds" :key="round.id">
                  {{ $t('round.qualify_for') }} {{ round.name }}
                </option>
              </select>
            </div>
          </div>
          <div class="o-layout__item u-1-of-3-at-small" :key="rank.rankingType+'main'">
            <div class="c-input">
              <input v-model="mainRankingType" :name="'main-' + round.index"
                     :id="'main-' + round.index + '-' +rank.rankingType" :value="rank.rankingType"
                     type="radio" class="c-input__field c-input__field-radiobutton"/>
              <label :for="'main-' + round.index + '-' +rank.rankingType"
                     class="c-input__label c-input__label-radiobutton">
                {{ $t('rankingType.main') }}
              </label>
            </div>
          </div>
        </template>
        <div class="o-layout__item">
          <div v-on:click="save" class="c-button c-button--primary">
            {{ $t('save') }}
          </div>
          <div v-on:click="remove" class="c-button c-button--secondary"
               v-if="round.index > 0 && rounds.length == 0">
            {{ $t('delete') }}
          </div>
        </div>
      </div>
    </form>
  </div>
</template>
<script>
import clone from 'lodash/cloneDeep';
import find from 'lodash/find';

export default {
  props: ['id', 'rounds'],
  data: function () {
    return {
      name: null,
      rankings: [],
      mainRankingType: null,
    };
  },
  computed: {
    round: function () {
      return find(this.$store.state.eventDiscipline.rounds, (item) => {
        return item.id === this.id;
      });
    },
  },
  created: function () {
    this.name = this.round.name;
    this.rankings = clone(this.round.rankings);
    this.mainRankingType = this.round.mainRankingType;
  },
  methods: {
    save: function () {
      const data = {
        id: this.round.id,
        name: this.name,
        rankings: this.rankings,
        mainRankingType: this.mainRankingType,
      }
      this.$notify.saveAsync(
        this.$t('round'),
        this.$store.dispatch('round.save', data),
      );
    },
    remove: function () {
      this.$modal.show({
        title: this.$t('delete.confirm.title'),
        message: this.$t('delete.confirm.text', {item: this.round.name}),
        onConfirm: () => {
          this.$notify.removeAsync(
            this.$t('round'),
            this.$store.dispatch('round.remove', this.round),
          );
        }
      });
    },
  },
};
</script>

<style scoped>
</style>
