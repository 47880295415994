<template>
  <div class="c-main">
    <div class="c-list">
      <div class="c-list__header c-list__header-dashboard">
        <div class="o-layout c-list__row">
          <div class="o-layout__item">
            <h3 class="c-title c-title--page-section">{{panel.name}} - {{$t('rotation')}} {{rotation+1}}</h3>
          </div>
        </div>
      </div>
      <template v-if="passes.length">
        <template v-for="pass in passes">
          <div :key="'h-' + pass.exerciseTypeId" class="c-list__header c-list__header-dashboard" v-if="passes.length > 1">
            <div class="o-layout c-list__row">
              <div class="o-layout__item">
                <h4 class="c-title--rotation c-title--rotation-active">
                  {{$t('exercise.type.'+ pass.exerciseTypeId)}}
                  <span v-if="session.switchPanels"> - {{blockTitle(pass.block)}}</span>
                </h4>
              </div>
            </div>
          </div>
          <div :key="'ex-' + pass.exerciseTypeId" class="c-list__body">
            <template v-for="blockPart in pass.participations" >
              <div v-if="blockPart.filler" :key="'ex-' + pass.exerciseTypeId + '|' + blockPart.id"
                   class="o-layout u-margin-bottom-medium c-exercise" >
                <div class="o-layout__item u-1-of-1 u-1-of-6-at-extralarge c-exercise__status">
                  <span class="c-exercise__status-label">{{$t('session.order.blockFiller')}}</span>
                </div>
              </div>
              <exercise v-else :key="'ex-' + pass.exerciseTypeId + '|' + blockPart.id"
                        :blockParticipation="blockPart" :exerciseTypeId="pass.exerciseTypeId"
                        :panelId="panel.id" pass="0" @open="openExercise($event)"/>
            </template>
          </div>
        </template>
      </template>
    </div>

    <footer class="c-footer c-footer--bottom-sticky c-footer--bottom-sticky-fixed-height o-layout">
      <div class="o-layout__item u-1-of-2-at-tiny">
      </div>

      <div class="o-layout__item u-1-of-2-at-tiny u-text-right">
        <sceRouteButton :route="{ name: 'admin.event.discipline.dashboard.session'}" label="close"
                        class="c-button--primary c-button--large" />
      </div>
    </footer>
  </div>
</template>

<script>
  import store from 'client/store';

  import find from 'lodash/find';

  import blockLib from 'client/lib/block.js';

  export default {
    name:'block-dashboard',
    props: ['panelId', 'rotation'],
    data: function() {
      return {
        passes: [],
      }
    },
    components: {
      "exercise": require('client/components/models/blockExercise.vue').default,
    },
    computed: {
      panel: function() {
        return find(store.state.panels.items, item => { return item.id === this.panelId });
      },
      session: function() {
        return find(store.state.sessions.items, item => { return item.id === this.$route.params.sessionId });
      },
    },
    created: function() {
      this.init();
    },
    watch: {
      blockParticipations: {
        handler: function() {
          this.init();
        },
        deep: true
      }
    },
    methods: {
      init: function() {
        if (this.panel) {
          this.passes = blockLib.getPanelOrder(this.panel, this.rotation);
        }
      },
      blockTitle: function(block) {
        return blockLib.title(block, false);
      },
      openExercise: function(event) {
        this.$navigation.goto({name: 'admin.event.discipline.dashboard.exercise',
          params: { panelId: this.panelId, rotation: this.rotation, exerciseId: event.exerciseId, pass: 1}});
      }
    }
  };
</script>

<style scoped>
  .panelExercises {
    clear: both;
  }
</style>
