<template>
  <div class="c-timer">
    <template v-if="! editing">
      <div class="c-timer__left">
        <div class="c-timer__display">{{ display.name }}</div>
        <div class="c-timer__text">{{ display.timer.text }}</div>
      </div>
      <div class="c-timer__time">{{ time }}</div>
    </template>
    <template v-if="editing" class="c-timer">
      <div class="c-timer__left o-layout">
        <div class="o-layout__item u-3-of-4-at-large">
          <sceSelect v-if="isNew" v-model="displayId" label="display" :options="freeDisplays" :config="{localized: false}" />
          <div v-else class="c-timer__display">{{ display.name }}</div>
        </div>
        <div class="o-layout__item u-1-of-4-at-large">
          <div v-if="isNew" class="c-input c-input--text">
            <label class="c-input__label">{{$t('time')}}</label>
            <timePicker v-model="timeObject" id="time" format="mm:ss" close-on-complete manual-input
                        input-class="c-input__field" input-width="100%" />
          </div>
        </div>
        <div class="o-layout__item u-3-of-4-at-large">
          <sceTextField v-model="timer.text" label="text" />
        </div>
        <div class="o-layout__item u-1-of-4-at-large">
          <div class="c-timer__buttons">
            <sceButton class="c-button--primary c-button--small" icon="svg-symbols.svg#play"
                       v-if="timer.status !== 'active'" v-on:click="play()" />
            <sceButton class="c-button--primary c-button--small" icon="svg-symbols.svg#pause"
                       v-if="timer.status === 'active'" v-on:click="pause()" />
            <sceButton v-if="! isNew" class="c-button--primary c-button--small" icon="svg-symbols.svg#synchronise"
                       v-on:click="reset()" />
          </div>
        </div>
      </div>
      <div class="c-timer__time"><span v-if="! isNew">{{ time }}</span></div>
    </template>
    <sceButton class="c-button--primary c-button--tiny" icon="svg-symbols.svg#settings" v-on:click="toggleEdit()"/>
    <sceButton class="c-button--primary c-button--tiny" icon="svg-symbols.svg#close" v-on:click="remove()"/>
  </div>
</template>

<script>
import numeral from 'numeral'
import cloneDeep from 'lodash/cloneDeep'
import filter from 'lodash/filter'
import includes from 'lodash/includes'
import merge from 'lodash/merge'
import sortBy from 'lodash/sortBy'

import VueTimepicker from 'vue2-timepicker'
import 'vue2-timepicker/dist/VueTimepicker.css'

export default {
  name: 'timer',
  components: {
    timePicker: VueTimepicker,
  },
  props: ['display', 'preset', 'ts'],
  data: function() {
    return {
      time: '',
      active: false,
      displayId: null,
      timeObject: {},
      timer: {
        duration: null,
        text: null,
        startTime: null,
        pauseTime: null,
        status: 'prepare',
      },
      editing: false,
      isNew: false,
    }
  },
  computed: {
    freeDisplays: function() {
      return sortBy(filter(this.$store.state.displays.items, d =>
        d.config.timer.active && ! d.timer?.status && includes(d.eventDisciplines, this.$route.params.disciplineId)
      ), 'name');
    }
  },
  created: function () {
    if (this.display) {
      this.timer = cloneDeep(this.display.timer)
    } else {
      this.timer = merge(this.timer, this.preset)
      this.editing = true
      this.isNew = true
    }

    if (! this.isNew) {
      setInterval(() => {
        let distance = 0;
        const status = this.display.timer.status
        const duration = this.display.timer.duration * 1000

        if (status !== 'prepare') {
          const now = status === 'active' ? new Date(this.ts.now()) : this.display.timer.pauseTime
          const start = (new Date(this.display.timer.startTime)).getTime()
          distance = now - start;

          if (distance < 0) {
            distance = duration;
            this.active = false;
          } else if (distance > duration) {
            distance = duration;
            this.active = false
          } else {
            this.active = true
          }
        }

        distance = duration - distance;

        // Time calculations for days, hours, minutes and seconds
        const minutes = numeral(Math.floor(distance / (1000 * 60))).format('0');
        const seconds = numeral(Math.floor((distance % (1000 * 60)) / 1000)).format('00');

        this.time = minutes + ':' + seconds
      }, 250)
    }
  },
  methods: {
    toggleEdit: function() {
      console.log('toggle edit')
      this.editing = ! this.editing
    },
    play: function() {
      if (this.isNew) {
        if (! this.timeObject.mm || ! this.timeObject.ss || ! this.displayId) return
        this.timer.duration = parseInt(this.timeObject.mm) * 60 + parseInt(this.timeObject.ss)
        this.timer.startTime = new Date(this.ts.now()).getTime()
      } else {
        if (this.timer.status === 'paused') {
          this.timer.status = 'active'
          this.timer.startTime = new Date(this.ts.now()).getTime() - (this.timer.pauseTime - this.timer.startTime)
        }
      }
      this.timer.status = 'active'
      this.saveTimer(this.timer)
      this.$emit('close')
    },
    pause: function() {
      this.timer.status = 'paused'
      this.timer.pauseTime = new Date(this.ts.now()).getTime()
      this.saveTimer(this.timer)
    },
    reset: function() {
      this.timer.pauseTime = null
      this.timer.startTime = new Date(this.ts.now()).getTime()
      this.saveTimer(this.timer)
    },
    remove: function() {
      if (this.isNew) {
        this.$emit('close')
        return
      }
      this.saveTimer({})
    },
    saveTimer: function(timer) {
      let displayId = this.displayId
      if (! this.isNew) {
        displayId = this.display.id
      }

      this.$store.dispatch('display.setTimer', {displayId, timer})
    }
  }
}
</script>

<style scoped>

</style>
