<template>
  <div class="o-wrapper o-wrapper--huge c-wrapper">
    <vue-progress-bar></vue-progress-bar>
    <div class="c-wrapper--min-100vh">
      <header class="c-header o-wrapper o-wrapper--huge js-header" :class="{'c-header--hidden-on-hover': fullscreen}">
        <router-link :to="{ name: 'home'}" class="c-logo__link">
          <div class="c-logo"></div>
        </router-link>
        <h1 class="c-header__title u-push-1-of-5-at-medium u-3-of-4-at-medium"
            :title="headerTitle()">{{headerTitle()}}</h1>

        <div class="c-header__user-actions">

          <router-link class="c-header__user" :to="{ name: 'logout'}">{{$t('logout')}}</router-link>
          <button class="c-button c-button--ghost c-button--tiny c-button--icon c-header__fullscreen-button" v-on:click="toggleFullscreen">
            <svg role="img" class="c-button__icon">
              <use v-if="!fullscreen" xlink:href="images/svg-symbols.svg#enter-fullscreen"></use>
              <use v-else xlink:href="images/svg-symbols.svg#exit-fullscreen"></use>
            </svg>
          </button>
        </div>

        <breadcrump/>
      </header>
      <div class="c-main-wrapper o-layout o-layout--main">
        <div class="c-sidebar o-layout__item" :class="{'c-sidebar--hidden-on-hover': fullscreen}">
          <router-view name="menu"></router-view>

          <div class="u-margin-top-medium u-padding-small">
            version: {{version}} <br/>
            <a href="https://www.scoreexpress.be/releases" target="_blank">{{ $t('versions') }}</a>
          </div>
        </div>
        <div class="c-main c-main--after-sidebar o-layout__item"  :class="{'c-main--fullscreen': fullscreen}">
          <router-view></router-view>
        </div>
      </div>
    </div>
    <modalSimple />
  </div>
</template>

<script>
  export default {
    name: 'adminWrapper',
    components: {
      breadcrump: require('@/components/breadcrump.vue').default,
    },
    data: function() {
      return {
        fullscreen: false
      }
    },
    computed: {
      event: function() {
        return this.$store.state.events.current
      },
      user: function () {
        return this.$store.state.user.instance;
      },
      version: function() {
        return this.$store.state.environment.appVersion;
      }
    },
    methods: {
      toggleFullscreen: function (e) {
        this.fullscreen = ! this.fullscreen
        e.target.parentElement.blur()
      },
      headerTitle: function() {
        const event = !! this.$route.params.eventId && this.event
        return event ? this.event.title : this.user.name ? this.user.name : this.user.username;
      }
    }
  };
</script>
