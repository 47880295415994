<template>
  <div :class="['c-collapsible', noLabel && 'c-collapsible--no-label']" data-collapsible-item>
    <h3 class="c-collapsible__title u-h6">
      <button :class="[
                        'c-button c-collapsible__button',
                        icon && 'c-button--icon',
                        noLabel && 'c-collapsible__button--no-label'
                    ]"
              :id="id + '-header'"
              :aria-controls="id + '-panel'"
              :aria-expanded="isExpanded"
              @click="toggle">
        {{ noLabel ? '' : title }}
        <svg role="img" class="c-collapsible__icon">
          <use :xlink:href="`images/svg-symbols.svg#${isExpanded ? 'arrow-down' : 'arrow-right'}`"></use>
        </svg>
      </button>

    </h3>

    <section :class="[noLabel && 'c-collapsible__content--no-label']"
             :id="id + '-panel'"
             :aria-labelledby="id + '-header'" >
      <slot name="header" />
      <div :class="['c-collapsible__content', !isExpanded && 'c-collapsible__content-hidden']" ref="panel">
        <slot name="content" />
      </div>
    </section>
  </div>
</template>


<script>
  export default {
    name: "collapsible",
    components: {  },
    props: {
      id: String,
      title: String,
      open: Boolean,
      icon: Boolean,
      noLabel: Boolean,
    },
    data() {
      return {
        isExpanded: false,
        target: null,
      };
    },
    mounted() {
      this.target = this.$refs.panel;
      this.isExpanded = this.open;
    },
    methods: {
      toggle() {
        this.isExpanded = !this.isExpanded;
        !this.target.classList.contains("c-collapsible__content-animation") && this.target.classList.add("c-collapsible__content-animation");
      },
      collapse() {
        this.isExpanded = false;
        ! this.target.classList.contains("c-collapsible__content-animation") && this.target.classList.add("c-collapsible__content-animation");
      },
      expand() {
        this.isExpanded = true;
        ! this.target.classList.contains("c-collapsible__content-animation") && this.target.classList.add("c-collapsible__content-animation");
      }
    }

  };
</script>
