<template>
  <div class="o-layout u-margin-bottom-large">
    <div class="o-layout__item">
      <table class="o-table o-table--fixed c-table--results">
        <thead>
          <tr>
            <th class="c-table-cell__valign-bottom c-table-text__regular">
              {{$t('select')}}
            </th>
            <th v-for="types of data.rankingTypes" :key="'roundTH-' + types.round.id" :colspan="types.count">
              {{ types.round.name }}
            </th>
          </tr>
          <tr>
            <th class="c-table-cell__valign-bottom c-table-text__regular">
              <a href="#" v-on:click.prevent="select(true)" class="">
                {{$t('all')}}
              </a>&nbsp;
              <a href="#" v-on:click.prevent="select(false)" class="">
                {{$t('none')}}
              </a>
            </th>
            <template v-for="roundTypes in data.rankingTypes">
              <th v-for="type in roundTypes.rankingTypes" :colspan="typeColspan(type)"
                  :key="'group-' + roundTypes.round.id + '-' + type.type + '-' + type.exerciseTypeId">
                {{type.exerciseTypeId ? $t('exercise.type.' + type.exerciseTypeId) : $t('rankingType.' + type.type)}}
              </th>
            </template>
          </tr>
          <tr>
            <th class="c-table-cell__valign-bottom c-table-text__regular">
              {{$t('category')}}
            </th>
            <template v-for="roundTypes in data.rankingTypes">
              <template v-for="type in roundTypes.rankingTypes">
                <th v-for="(subType, st_index) in type.subTypes"
                    :key="getTypeKey(roundTypes.round.id, type.type, subType.subType, type.exerciseTypeId, subType.region)"
                    class="c-table-text__regular"
                    :class="{'is-first': st_index === 0 }">
                  <template v-if="subType.subType !== 'regional'">
                    {{$t('rankingType.' + subType.subType)}}
                  </template>
                  <template v-else>
                    {{getRegionName(subType.region)}}
                  </template>
                </th>
              </template>
            </template>
          </tr>
        </thead>
        <tbody class="c-table__body">
          <tr v-for="categorySelection in data.rankingSelection" :key="categorySelection.categoryId">
            <td class="c-table__grouped-cells">
              <div class="c-table__cell-content">
                {{categorySelection.category.name}}
              </div>
            </td>
            <td class="c-table__grouped-cells"
                v-for="type in categorySelection.types"
                :key="getTypeKey(type.roundId, type.type, type.subType, type.exerciseTypeId, type.region) + '-' + categorySelection.category.id"
                :class="{'is-first': getResultType(categorySelection.category.id, type.type, type.exerciseTypeId)}">
              <div class="c-table__cell-content">
                <div class="c-input" v-if="type.rankingId">
                  <input v-model="type.selected" :disabled="type.empty"
                        :id="type.type + '-' + type.subType + '-'+type.rankingId"
                        type="checkbox" class="c-input__field c-input__field-checkbox"
                        :class="{'c-button--disabled': type.empty}"/>
                  <label :for="type.type + '-' + type.subType + '-'+type.rankingId" class="c-input__label c-input__label-checkbox">
                  </label>
                  <p class="u-margin-bottom-none">({{ type.itemCount }})</p>
                </div>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <div class="o-layout__item" v-if="! judgeScores">
      <div class="c-input">
        <input v-model="medalsOnly" id="medalsOnly" name="medalsOnly" type="checkbox" class="c-input__field c-input__field-checkbox" />
        <label for="medalsOnly" class="c-input__label c-input__label-checkbox">{{$t('results.medalsOnly')}}</label>
      </div>
    </div>

    <div class="o-layout__item" v-if="sessionId">
      <div class="c-input">
        <input v-model="sessionFilter" id="sessionFilter" name="sessionFilter" type="checkbox" class="c-input__field c-input__field-checkbox" />
        <label for="sessionFilter" class="c-input__label c-input__label-checkbox">{{$t('results.sessionFilter')}}</label>
      </div>
    </div>

    <div class="o-layout__item u-1-of-3-at-small">
      <div class="c-input c-input--inline">
        <label class="c-input__label" for="pageBreaks">{{$t('pagebreaks')}}</label>
        <select class="c-input__field c-input__field-select" v-model="pageBreaks" name="pageBreaks" id="pageBreaks">
          <option v-for="option in $options.pageBreaksOptions" :key="option" :value="option">
            {{$t('pagebreak.' + option)}}
          </option>
        </select>
      </div>
    </div>

    <div class="o-layout__item u-2-of-3-at-small u-text-right">
      <button class="c-button c-button--primary c-button--large" v-on:click="createPDF('report')">
        {{$t('export.pdf')}}
      </button>
      <button v-if="! judgeScores && ! hasMultiples()" class="c-button c-button--primary c-button--large"
              v-on:click="createPDF('certificates', false)">
        {{$t('export.certificates')}}
      </button>
      <dropDown v-else-if="! judgeScores"  menuKey="results" :label="$t('export.certificates')" large primary>
        <template slot="content">
          <button class="c-button c-dropdown__button c-button--secondary" tag="button"
                  v-on:click="createPDF('certificates', false)">>
            {{$t("export.certificates.single")}}
          </button>
          <button class="c-button c-dropdown__button c-button--secondary" tag="button"
                  v-on:click="createPDF('certificates', true)">>
            {{$t("export.certificates.multiple")}}
          </button>
        </template>
      </dropDown>
      <button class="c-button c-button--primary c-button--large" v-on:click="createCSV()">
        {{$t('export.csv')}}
      </button>
    </div>
  </div>
</template>

<script>
  import rankingLib from 'client/lib/ranking.js';
  import report from 'client/lib/report.js';

  import concat from 'lodash/concat'
  import filter from 'lodash/filter'
  import find from 'lodash/find'
  import forEach from 'lodash/forEach'
  import reduce from 'lodash/reduce'

  export default {
    name: "sessionResults",
    props: ['sessionId', 'data', 'judgeScores'],
    pageBreaksOptions: ['never', 'category', 'ranking'],
    components: {
      dropDown: require('client/components/dropDown.vue').default,
    },
    data: function () {
      return {
        round: null,
        country: undefined,
        sessionFilter: false,
        medalsOnly: false,
        pageBreaks: 'category',
      }
    },
    computed: {
      session: function () {
        return find(this.$store.state.sessions.items, item => item.id === this.sessionId)
      },
      rankings: function () {
        return this.$store.state.rankings.items
      },
      eventDiscipline: function () {
        return find(this.$store.state.eventDisciplines.items, item => item.id === this.$route.params.disciplineId)
      },
      discipline: function () {
        return this.$store.state.eventDiscipline.discipline
      },
    },
    created: function () {
      this.init();
    },
    methods: {
      init: function () {
        const event = this.$store.state.events.current
        if (event.countryId) {
          this.country = find(this.$store.state.events.countries, item => item.id === event.countryId)
        }
        else {
          this.country = undefined
        }
      },
      typeColspan: function(type) {
        let span = type.subTypes.length
        return span
      },
      hasMultiples: function() {
        return reduce(this.data.rankingTypes, (res, rt) => res || rt.hasGroups || rt.hasTeams, false)
      },
      getRegionName: function(region) {
        const cRegion = find(this.country.regions, item => item.id === region)
        return cRegion ? cRegion.name : '';
      },
      getRound: function(ranking) {
        return find(this.$store.state.eventDiscipline.rounds, item => item.id === ranking.roundId)
      },
      getResultType: function (categoryId, type, exerciseTypeId) {
        let isFirstOfType = false;
        if(categoryId !== this.previousCategoryId
          || type !== this.previousResultType
          || exerciseTypeId !== this.previousExerciseTypeId) {
          isFirstOfType = true;
        }
        this.previousCategoryId = categoryId;
        this.previousResultType = type;
        this.previousExerciseTypeId = exerciseTypeId;
        return isFirstOfType;
      },
      select: function(value) {
        forEach(this.data.rankingSelection, selection => {
          forEach(selection.types, type => {
            if (type.rankingId && ! type.empty) {
              type.selected = value
            }
          });
        });
      },
      getSectionFilter: function(selection, category) {
        let subDivision = 0
        if (this.sessionFilter) {
          const sessionCategory = find(this.session.categories, item => item.categoryId === category.id)
          subDivision = sessionCategory.subDivision
        }

        let awards = selection.subType === 'awards' ? 'awards' : false
        if (! awards && this.medalsOnly) {
          awards = 'medals'
        }

        return {
          awards,
          subDivision,
        }
      },
      getTypeKey: function(roundId, type, subType, event, region) {
        let key = roundId + '-' + type + '-' + subType
        if (type === 'EVENTS' || type === 'TEAM-EVENTS') {
          key += '-' + event
        }
        if (region) {
          key += '-' + region
        }
        return key
      },
      createPDF: function(type, multiple = false) {
        let sections = [];

        forEach(this.data.rankingSelection, categorySelection => {
          const selections = filter(categorySelection.types, item => {
            return item.selected && item.rankingId;
          });

          let count = 0;
          forEach(selections, selection => {
            const ranking = find(this.rankings, item => {
              return item.id === selection.rankingId;
            });
            const filter = this.getSectionFilter(selection, categorySelection.category)

            if (type === 'report') {
              if (this.judgeScores) {
                filter.judgeScores = 1
              }
              else if (ranking.rankingType === 'EVENTS' && this.discipline.config.judgeReport) {
                filter.judgeScores = 2
              }
              console.log('filter', filter)

              sections.push(rankingLib.preparePrint(
                ranking, categorySelection.category, this.getRound(ranking), this.discipline, filter))
              if (this.pageBreaks === 'ranking') {
                sections.push({
                  type: 'pagebreak',
                });
              } else {
                count++;
              }
            } else if (type === 'certificates') {
              sections.push(rankingLib.prepareCertificates(
                ranking, categorySelection.category, this.getRound(ranking), this.discipline, filter, multiple))
            }
          });

          if (type === 'report' && this.pageBreaks === 'category' && count) {
            sections.push({
              type: 'pagebreak',
            })
          }
        })

        if (type === 'report') {
          report.publishReport(this.eventDiscipline, sections)
        } else {
          report.publishCertificates(this.eventDiscipline, sections)
        }
      },
      createCSV: function() {
        let mFields = {
          main: [],
          exercises: [],
        };
        let mData = []

        forEach(this.data.rankingSelection, categorySelection => {
          const selections = filter(categorySelection.types, item => {
            return item.selected && item.rankingId;
          });

          forEach(selections, selection => {
            const ranking = find(this.rankings, item => {
              return item.id === selection.rankingId;
            });
            const filter = this.getSectionFilter(selection, categorySelection.category)

            let {fields, data} = rankingLib.prepareExport(ranking, categorySelection.category,
              this.getRound(ranking), this.discipline, filter, this.judgeScores)
            mFields = rankingLib.mergeExportFields(mFields, fields);
            mData = concat(mData, data);
          });
        });

        const fields = rankingLib.flattenExportFields(mFields)

        report.publishCSV2(mData, fields, 'results')
      },
    },
  }
</script>
