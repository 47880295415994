<template>
  <div class="c-main u-1-of-1">
    <div class="o-layout">
      <div class="o-layout__item">
        <h2 class="c-title c-title--page">{{$t('syncEvent.event')}}</h2>
      </div>
      <div class="o-layout__item">
        <h3 class="c-title c-title--page-section">{{event.title}}</h3>
      </div>
      <div class="o-layout__item u-1-of-1 u-1-of-2-at-medium">
        <img height="120" :src="event.logoUrl"/>
      </div>
      <div class="o-layout__item u-1-of-1 u-1-of-2-at-medium">
        <div class="c-input">
          <label class="c-input__label">{{$t('venue')}}</label>
          {{event.venue}}
        </div>
        <div class="c-input">
          <label class="c-input__label">{{$t('date')}}</label>
          {{format(event.date_from)}} - {{format(event.date_to)}}
        </div>
      </div>
      <div class="o-layout__item u-margin-top-medium">
        <template v-if="status === 'idle'">
          <button class="c-button c-button--primary c-button--large" @click="submit()">{{$t('start import')}}</button>
        </template>
        <template v-else-if="status !== 'finished'">
          <h3 class="c-title c-title--page-section">
            {{status === 'sync' ? $t('import.syncing') : $t('import.checking')}}
          </h3>
          <div class="sk-circle">
            <div class="sk-circle1 sk-child"></div>
            <div class="sk-circle2 sk-child"></div>
            <div class="sk-circle3 sk-child"></div>
            <div class="sk-circle4 sk-child"></div>
            <div class="sk-circle5 sk-child"></div>
            <div class="sk-circle6 sk-child"></div>
            <div class="sk-circle7 sk-child"></div>
            <div class="sk-circle8 sk-child"></div>
            <div class="sk-circle9 sk-child"></div>
            <div class="sk-circle10 sk-child"></div>
            <div class="sk-circle11 sk-child"></div>
            <div class="sk-circle12 sk-child"></div>
          </div>
        </template>
        <template v-else>
          <h3 class="c-title c-title--page-section">{{$t('import.successful')}}</h3>
          <router-link class="c-button c-button--primary c-button--large" :to="{ name: 'home'}">
            {{$t('events')}}
          </router-link>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
  const moment = require('moment');

  export default {
    name: "import",
    data: () => {
      return {
        status: 'idle',
        cycles: 0,
      }
    },
    computed: {
      event: function () {
        return this.$store.state.importer.event
      }
    },
    methods: {
      format: function (date) {
        return moment(date).format('DD/MM/YYYY');
      },
      sync: function() {
        if (this.cycles > 1) return;

        this.cycles += 1;
        this.status = 'sync';
        this.$store.dispatch("importer.event.start", this.$route.params.eventId).then(() => {
          this.status = 'check';

          this.$store.dispatch('importer.event.check', this.$route.params.eventId).then((result) => {
            console.log('check finished', result.body);
            if (result.body === 'success') {
              this.status = 'finished';
              this.$store.dispatch('event.insert', {event: this.event});
            } else {
              this.sync();
            }
          });
        })
      },
      submit: function () {
        this.cycles = 0;
        this.sync();
      }
    }
  }
</script>

<style scoped>
  .sk-circle {
    margin: 100px auto;
    width: 40px;
    height: 40px;
    position: relative;
  }

  .sk-circle .sk-child {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
  }

  .sk-circle .sk-child:before {
    content: '';
    display: block;
    margin: 0 auto;
    width: 15%;
    height: 15%;
    background-color: #333;
    border-radius: 100%;
    -webkit-animation: sk-circleBounceDelay 1.2s infinite ease-in-out both;
    animation: sk-circleBounceDelay 1.2s infinite ease-in-out both;
  }

  .sk-circle .sk-circle2 {
    -webkit-transform: rotate(30deg);
    -ms-transform: rotate(30deg);
    transform: rotate(30deg);
  }

  .sk-circle .sk-circle3 {
    -webkit-transform: rotate(60deg);
    -ms-transform: rotate(60deg);
    transform: rotate(60deg);
  }

  .sk-circle .sk-circle4 {
    -webkit-transform: rotate(90deg);
    -ms-transform: rotate(90deg);
    transform: rotate(90deg);
  }

  .sk-circle .sk-circle5 {
    -webkit-transform: rotate(120deg);
    -ms-transform: rotate(120deg);
    transform: rotate(120deg);
  }

  .sk-circle .sk-circle6 {
    -webkit-transform: rotate(150deg);
    -ms-transform: rotate(150deg);
    transform: rotate(150deg);
  }

  .sk-circle .sk-circle7 {
    -webkit-transform: rotate(180deg);
    -ms-transform: rotate(180deg);
    transform: rotate(180deg);
  }

  .sk-circle .sk-circle8 {
    -webkit-transform: rotate(210deg);
    -ms-transform: rotate(210deg);
    transform: rotate(210deg);
  }

  .sk-circle .sk-circle9 {
    -webkit-transform: rotate(240deg);
    -ms-transform: rotate(240deg);
    transform: rotate(240deg);
  }

  .sk-circle .sk-circle10 {
    -webkit-transform: rotate(270deg);
    -ms-transform: rotate(270deg);
    transform: rotate(270deg);
  }

  .sk-circle .sk-circle11 {
    -webkit-transform: rotate(300deg);
    -ms-transform: rotate(300deg);
    transform: rotate(300deg);
  }

  .sk-circle .sk-circle12 {
    -webkit-transform: rotate(330deg);
    -ms-transform: rotate(330deg);
    transform: rotate(330deg);
  }

  .sk-circle .sk-circle2:before {
    -webkit-animation-delay: -1.1s;
    animation-delay: -1.1s;
  }

  .sk-circle .sk-circle3:before {
    -webkit-animation-delay: -1s;
    animation-delay: -1s;
  }

  .sk-circle .sk-circle4:before {
    -webkit-animation-delay: -0.9s;
    animation-delay: -0.9s;
  }

  .sk-circle .sk-circle5:before {
    -webkit-animation-delay: -0.8s;
    animation-delay: -0.8s;
  }

  .sk-circle .sk-circle6:before {
    -webkit-animation-delay: -0.7s;
    animation-delay: -0.7s;
  }

  .sk-circle .sk-circle7:before {
    -webkit-animation-delay: -0.6s;
    animation-delay: -0.6s;
  }

  .sk-circle .sk-circle8:before {
    -webkit-animation-delay: -0.5s;
    animation-delay: -0.5s;
  }

  .sk-circle .sk-circle9:before {
    -webkit-animation-delay: -0.4s;
    animation-delay: -0.4s;
  }

  .sk-circle .sk-circle10:before {
    -webkit-animation-delay: -0.3s;
    animation-delay: -0.3s;
  }

  .sk-circle .sk-circle11:before {
    -webkit-animation-delay: -0.2s;
    animation-delay: -0.2s;
  }

  .sk-circle .sk-circle12:before {
    -webkit-animation-delay: -0.1s;
    animation-delay: -0.1s;
  }

  @-webkit-keyframes sk-circleBounceDelay {
    0%, 80%, 100% {
      -webkit-transform: scale(0);
      transform: scale(0);
    }
    40% {
      -webkit-transform: scale(1);
      transform: scale(1);
    }
  }

  @keyframes sk-circleBounceDelay {
    0%, 80%, 100% {
      -webkit-transform: scale(0);
      transform: scale(0);
    }
    40% {
      -webkit-transform: scale(1);
      transform: scale(1);
    }
  }
</style>
