<template>
  <div class="c-main">
    <h2 class="c-title c-title--page">{{$t('event.new')}}</h2>
    <event @submit="update($event)"></event>
  </div>
</template>

<script type="text/babel">
	export default {
		name: "event-add",
    components:{
      'event':require('./event.vue').default,
    },
		methods:{
			update:function(event){
        this.$store.dispatch('event.add', {
          event: event,
          userId: this.$store.state.user.instance.id,
        }).then(result => {
          this.$navigation.goto({name: 'admin.event.view', params: { eventId: result.id}});
        });
			}
		}
	};
</script>

<style>
</style>
