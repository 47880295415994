let distanceMatrix = []
distanceMatrix['bel-antw'] = []
distanceMatrix['bel-antw']['bel-antw'] = 0
distanceMatrix['bel-antw']['bel-limb'] = 1
distanceMatrix['bel-antw']['bel-vlbr'] = 1
distanceMatrix['bel-antw']['bel-wvl'] = 2
distanceMatrix['bel-antw']['bel-ovl'] = 1

distanceMatrix['bel-limb'] = [];
distanceMatrix['bel-limb']['bel-antw'] = 1
distanceMatrix['bel-limb']['bel-limb'] = 0
distanceMatrix['bel-limb']['bel-vlbr'] = 1
distanceMatrix['bel-limb']['bel-wvl'] = 3
distanceMatrix['bel-limb']['bel-ovl'] = 2

distanceMatrix['bel-vlbr'] = [];
distanceMatrix['bel-vlbr']['bel-antw'] = 1
distanceMatrix['bel-vlbr']['bel-limb'] = 1
distanceMatrix['bel-vlbr']['bel-vlbr'] = 0
distanceMatrix['bel-vlbr']['bel-wvl'] = 2
distanceMatrix['bel-vlbr']['bel-ovl'] = 1

distanceMatrix['bel-wvl'] = [];
distanceMatrix['bel-wvl']['bel-antw'] = 2
distanceMatrix['bel-wvl']['bel-limb'] = 3
distanceMatrix['bel-wvl']['bel-vlbr'] = 2
distanceMatrix['bel-wvl']['bel-wvl'] = 0
distanceMatrix['bel-wvl']['bel-ovl'] = 1

distanceMatrix['bel-ovl'] = []
distanceMatrix['bel-ovl']['bel-antw'] = 1
distanceMatrix['bel-ovl']['bel-limb'] = 2
distanceMatrix['bel-ovl']['bel-vlbr'] = 1
distanceMatrix['bel-ovl']['bel-wvl'] = 1
distanceMatrix['bel-ovl']['bel-ovl'] = 0

const distance = (regionId1, regionId2) => {
  return distanceMatrix[regionId1][regionId2]
}

export default { distance }
