<template>
  <div class="c-search-bar c-search-bar--small">
    <div class="o-layout">
      <div v-if="! config.nosort" class="o-layout__item c-search-bar__line">
        <sceSelect v-model="output.sortOrder" :options="sortFieldOptions()"
                   class="c-input--inline u-margin-right-small " />
        <sceButton icon="svg-symbols.svg#sort-asc" v-on:click="output.sortDir = 'asc'"
                   :class="{'c-button--tertiary': output.sortDir === 'asc', 'c-button--secondary': output.sortDir !== 'asc'}" />
        <sceButton icon="svg-symbols.svg#sort-desc" v-on:click="output.sortDir = 'desc'"
                   :class="{'c-button--tertiary': output.sortDir === 'desc', 'c-button--secondary': output.sortDir !== 'desc'}" />
      </div>
      <div class="o-layout__item c-search-bar__line">
        <sceTextField v-if="! config.nosearch" v-model="output.searchString"
                      class="c-input--inline c-input--icon c-input--icon-search" id="searchString"/>
      </div>
    </div>
  </div>
</template>

<script>
import searchBar from './searchBar.vue'

export default {
  name: "searchBarSmall",
  extends: searchBar,
}
</script>

<style scoped>

</style>
