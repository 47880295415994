<template>
  <div></div>
</template>

<script>
  export default {
    name: "dummy",
    mounted: function() {
      this.$navigation.goto({ name: 'admin.event.discipline.dashboard',
        params: {disciplineId: this.$route.params.disciplineId}});
    }
  }
</script>

<style scoped>
</style>
