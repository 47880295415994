<template>
  <div class="">
    <div class="o-layout">
      <div class="o-layout__item u-1-of-4-at-large u-1-of-2-at-small u-1-of-1 u-margin-bottom-medium">
        <sceSelect v-model="roundId" label="round" :options="rounds"
                   :config="{ localized: false }"/>
      </div>
    </div>

    <div class="o-layout">
      <div class="o-layout__item u-1-of-1 u-margin-bottom-medium">
        <div class="c-input">
          <label class="c-input__label">
            {{$t('categories')}}
          </label>
        </div>
      </div>
      <div v-for="category of roundCategories" :key="category.id" class="o-layout__item u-1-of-1">
        <div class="c-input">
          <input v-model="selection"  :id="'category-' + category.id" type="checkbox"
                 class="c-input__field c-input__field-checkbox" :value="category.id" />
          <label :for="'category-' + category.id" class="c-input__label c-input__label-checkbox">
            {{ category.name }}
          </label>
        </div>
      </div>
      <div class="o-layout__item">
        <div class="c-input--extra-info">
          {{ $t('planning.subdivisions.categories.note')}}
        </div>
      </div>
    </div>
    <div class="o-layout">
      <div class="o-layout__item">
        <sceRouteButton :route="{ name: 'admin.event.discipline.sessions'}" label="cancel"
                        class="c-button--ghost c-button--large" />
        <sceButton class="c-button--primary c-button--large" v-on:click="scheduleSubDivisions()"
                   label="createSubdivisions.execute" />
      </div>
    </div>
  </div>
</template>

<script>

import cloneDeep from "lodash/cloneDeep";
import find from "lodash/find";
import forEach from "lodash/forEach";
import sortBy from "lodash/sortBy";

import { createSubDivisions } from "@/lib/sortathletes/subDivisions"

export default {
  name: 'createSubdivisions',
  data: () => {
    return {
      roundId: null,
      selection: [],
      roundCategories: [],
    };
  },
  computed: {
    event: function () {
      return this.$store.state.events.current
    },
    eventDiscipline: function () {
      return find(
        this.$store.state.eventDisciplines.items,
        item => item.id === this.$route.params.disciplineId
      );
    },
    rounds: function() {
      return sortBy(this.$store.state.eventDiscipline.rounds, 'index')
    },
    categories: function() {
      return this.$store.state.categories.items
    },
    sessions: function() {
      return this.$store.state.sessions.items
    },
  },
  watch: {
    roundId: {
      immediate: true,
      handler: function() {
        if (this.roundId) {
          this.roundCategories = sortBy(this.categories.filter(c => {
            const catRound = c.rounds.find(cr => cr.roundId === this.roundId)
            return catRound.subDivisions > 1
          }), 'index')
          this.selection = this.roundCategories.map(c => c.id)
        } else {
          this.selection = []
          this.roundCategories = []
        }
      }
    }
  },
  created: function() {
    if (this.rounds.length) {
      this.roundId = this.rounds[0].id
    }
  },
  methods: {
    scheduleSubDivisions: function() {
      this.$notify.msgAsync(
        new Promise((resolve) => {
          const categories = this.categories.filter(c => this.selection.includes(c.id))
          const result = createSubDivisions(
            categories, this.sessions, this.rounds[0].id, this.event.regionId)

          forEach(result, item => {
            const round = item.participation.rounds[0];
            if (item.subDivision !== round.subDivision) {
              let data = cloneDeep(item.participation);
              data.rounds[0].subDivision = item.subDivision;
              this.$store.dispatch('participation.save', {data: data});
            }
          });

          resolve()
        }),
        this.$t('planning.divideSubDivisions.processing'),
        this.$t('planning.divideSubDivisions.success'),
        this.$t('planning.divideSubDivisions.failed')
      )
    },
  }
}
</script>

<style scoped>

</style>
