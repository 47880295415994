<template>
  <div class="c-main" ref="focusbody">
    <h2 class="c-title c-title--page">{{$t('scoreinput')}}</h2>
    <component :is="type" />
  </div>
</template>

<script>
  export default {
    name: "scoreWrapper",
    components: {
      blockInput: require('./scoreInputBlock.vue').default,
      singleInput: require('./scoreInput.vue').default,
    },
    computed: {
      type: function() {
        const discipline = this.$store.state.eventDiscipline.discipline;
        if (discipline.id === 'discipline-recreatt') {
          return 'blockInput';
        }
        return 'singleInput';
      }
    },
    mounted: function() {}
  }
</script>

<style scoped>
</style>
