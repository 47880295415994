<template>
  <div class="c-main-flex-1">
    <h2 class="c-title c-title--page">{{$t('qr.login')}}</h2>

    <div class="o-layout">
      <div class="o-layout__item">
        <h3 class="c-title c-title--page-section">{{$t('urlBase')}}</h3>
        {{host}}
      </div>
      <div class="o-layout__item u-padding-top-large">
        <h3 class="c-title c-title--page-section">{{$t('judge.console')}}</h3>
        <canvas class="code" ref="code" width="250" height="250"></canvas>
      </div>
      <div class="o-layout__item u-padding-top-large">
        <a :href="url">{{url}}</a>
      </div>
    </div>

    <div v-if="isLocal" class="o-layout">
      <div class="o-layout__item u-padding-top-large u-padding-bottom-medium">
        <h3 class="c-title c-title--page-section">{{$t('urlBase.config')}}</h3>
      </div>
      <div class="o-layout__item u-1-of-2">
        <p><strong>{{$t('urlBase.urlList')}}</strong></p>
        <div class="c-list__body">
          <div v-for="url of config.urls" :key="url.address"  class="c-list__row o-layout">
            <div class="o-layout__item u-1-of-2">
              <p><strong>{{url.address}}</strong>
                <br/>{{url.label}}</p>
            </div>
            <div v-if="url.address !== config.localURL" class="o-layout__item u-1-of-2">
              <div class="c-button c-button--primary c-button--small" v-on:click.prevent="setURL(url.address)">
                {{$t('select')}}
              </div>
              <div class="c-button c-button--secondary c-button--small" v-on:click.prevent="removeURL(url.address)">
                {{$t('delete')}}
              </div>
            </div>
            <div v-else class="o-layout__item u-1-of-2">
              <svg role="img" class="check">
                <use xlink:href="images/check.svg#Symbols"></use>
              </svg>
            </div>
          </div>
        </div>
      </div>
      <div class="o-layout__item u-1-of-2">
        <p><strong>{{$t('urlBase.addURL')}}</strong></p>
        <div class="c-input c-input--text">
          <label class="c-input__label" for="addAddress">{{$t('addURL.address')}}</label>
          <input v-model="addURL.address" class="c-input__field" name="addAddress" type="text" id="addAddress"/>
        </div>
        <div class="c-input c-input--text">
          <label class="c-input__label" for="addLabel">{{$t('label')}}</label>
          <input v-model="addURL.label" class="c-input__field" name="addLabel" type="text" id="addLabel"/>
        </div>
        <div class="c-button c-button--primary c-button--large" v-on:click.prevent="saveURL()">
          {{$t('addURL.set')}}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import QRious from 'qrious'

  import clone from 'lodash/cloneDeep'
  import filter from 'lodash/filter'

  export default {
    name: "qr-wrapper",
    data: function() {
      return {
        url: '',
        addURL: {
          address: '',
          label: '',
        }
      }
    },
    computed: {
      isLocal: function() {
        return this.$store.state.environment.local
      },
      host: function() {
        return this.$store.state.environment.baseUrl
      },
      config: function() {
        return this.$store.state.environment.config
      },
    },
    created: function () {
      this.initURL()
    },
    mounted: function () {
      new QRious({
        size: 250,
        element: this.$refs.code,
        value: this.url,
      })
    },
    methods: {
      saveURL: function() {
        const config = clone(this.config)
        config.urls.push({
          address: this.addURL.address,
          label: this.addURL.label,
        });
        config.localURL = this.addURL.address;
        console.log(config)

        this.updateConfig(config);
        this.addURL = {
          address: '',
          label: '',
        };
      },
      setURL: function(url) {
        const config = clone(this.config);
        config.localURL = url;

        this.updateConfig(config);
      },
      removeURL: function(url) {
        const config = clone(this.config);
        config.urls = filter(config.urls, item => item.address !== url);

        this.updateConfig(config);
      },
      updateConfig: function(newConfig) {
        newConfig.urls.forEach((url, i) => {
          url.id = i+1
        })
        this.$store.dispatch('environment.updateConfig', newConfig).then(() => this.initURL());
      },
      initURL: function() {
        const token = localStorage.getItem('id_token');
        const userId = localStorage.getItem('id_user');
        this.url = this.host + '/#/login?userId=' + userId + '&token=' + token + '&destination=/judge/' + this.$route.params.eventId;
      }
    }
  };
</script>

<style scoped>
  .code {
    margin: 50px auto auto;
    display: block;
  }

  .check {
    height: 25px;
    width: 25px;
    fill: #4D59A1;
  }
</style>
