<template>
  <div class="session u-padding-bottom-large">
    <div class="o-layout">
      <div class="o-layout__item u-text-right">
        <div class="c-button c-button--primary c-button--large"
             v-if="session.status==='planned'" v-on:click="switchStatus('active')">
          {{$t('session.button.activate')}}
        </div>
        <div class="c-button c-button--primary c-button--large"
             v-if="session.status==='active'" v-on:click="switchStatus('finished')">
          {{$t('session.button.finish')}}
        </div>
        <div class="c-button c-button--secondary c-button--large"
             v-if="session.status==='active'" v-on:click="switchStatus('planned')">
          {{$t('session.button.deactivate')}}
        </div>
        <div class="c-button c-button--primary c-button--large"
             v-if="session.status==='finished'" v-on:click="switchStatus('active')">
          {{$t('session.button.reactivate')}}
        </div>
      </div>
    </div>
    <div class="rotations">
      <rotation v-for="rotation in session.rotationsSet" :key="rotation.index" :session="session" :rotation="rotation" />
    </div>

    <div class="c-tabs c-main">
      <div class="c-tab__tab-bar u-margin-bottom-medium">
        <a v-for="tab in tabs" :key="tab.name" v-on:click="setActiveTab(tab.name)"
           class="c-button c-button--large c-button--ghost c-tabs__tab-link"
           :class="{'is-active': activeTab === tab.name}">
          {{ tab.label }}
        </a>
      </div>
      <div v-if="activeTab === 'results'" class="c-tabs__panel c-main">
        <sessionResults :sessionId="session.id" :data="results"/>
      </div>
      <div v-if="activeTab === 'selections'" class="c-tabs__panel c-main">
        <sessionSelections :sessionId="session.id" :data="selections" />
      </div>
    </div>

    <!--<div class="selections" v-if="rankings.length">
      <sceRouteButton class="c-button--primary"
        :route="{name: 'admin.event.discipline.dashboard.selections'}" label="dashBoard.makeSelections"/>
      <h3 class="c-title c-title--page-section">{{$t('session.participantSelection')}}</h3>
      <div v-for="ranking of rankings" :key="ranking.id">
        <h4 class="c-title c-title--page-section">
          Select for round {{getRound(ranking.nextRound).name}} based on {{$t('rankingType.' + ranking.rankingType)}} result
        </h4>
        <div class="o-layout">
          <div class="o-layout__item u-1-of-2-at-small u-1-of-4-at-large">
            <div class="c-input">
              <label class="c-input__label" :for="'selection.category-'+ranking.rankingType">
                {{$t('category')}}
              </label>
              <select class="c-input__field c-input__field-select" v-model="selection[ranking.rankingType].categoryId"
                      :id="'selection.category-'+ranking.rankingType">
                <option v-for="sessionCategory of session.categories" :key="sessionCategory.categoryId"
                        :value="sessionCategory.categoryId">
                  {{getCategory(sessionCategory.categoryId).name}}
                </option>
              </select>
            </div>
          </div>
          <div class="o-layout__item u-1-of-2-at-small u-1-of-4-at-large">
            <div class="c-input">
              <label class="c-input__label" :for="'selection.session-'+ranking.rankingType">
                {{$t('session')}}
              </label>
              <select class="c-input__field c-input__field-select" v-model="selection[ranking.rankingType].sessionId"
                      :id="'selection.session-'+ranking.rankingType">
                <option v-for="session of getSelectionSessions(ranking)" :key="session.id" :value="session.id">
                  {{session.name}}
                </option>
              </select>
            </div>
          </div>
          <div class="o-layout__item u-1-of-2-at-small u-1-of-4-at-large">
            <div v-if="selection[ranking.rankingType].sessionId" class="c-input">
              <label class="c-input__label" :for="'selection.rotation-'+ranking.rankingType">
                {{$t('rotation')}}
              </label>
              <select class="c-input__field c-input__field-select" v-model="selection[ranking.rankingType].rotation"
                      :id="'selection.rotation-' + ranking.rankingType">
                <option v-for="rotation in getSelectionSession(ranking.rankingType).rotations" :key="rotation" :value="rotation-1">
                  {{rotation}}
                </option>
              </select>
            </div>
          </div>
          <div v-if="selection[ranking.rankingType].sessionId && rotationType !== 'mixed'"
               class="o-layout__item u-1-of-2-at-small u-1-of-4-at-large">
            <div class="c-input">
              <label class="c-input__label" :for="'selection.set-'+ranking.rankingType">
                {{$t(setLabel)}}
              </label>
              <select class="c-input__field c-input__field-select" v-model="selection[ranking.rankingType].set"
                      :id="'selection.set-' + ranking.rankingType">
                <option v-for="set in getSelectionSession(ranking.rankingType).sets" :key="set" :value="set">
                  {{set}}
                </option>
              </select>
            </div>
          </div>
          <div v-if="selection[ranking.rankingType].sessionId && (rotationType === 'mixed' || ranking.rankingType === 'EVENTS')"
               class="o-layout__item u-1-of-2-at-small u-1-of-4-at-large">
            <div class="c-input">
              <label class="c-input__label" for="selection.exerciseTypeId">{{$t('exercise')}}</label>
              <select class="c-input__field c-input__field-select" v-model="selection[ranking.rankingType].exerciseTypeId"
                      id="selection.exerciseTypeId">
                <option v-for="exerciseTypeId in getSelectionExerciseTypes(ranking)"
                        :key="exerciseTypeId" :value="exerciseTypeId">
                  {{$t('exercise.type.' + exerciseTypeId)}}
                </option>
              </select>
            </div>
          </div>
        </div>
        <div class="o-layout">
          <div class="o-layout__item u-text-right">
            <div class="c-button c-button--primary c-button--large"
                 v-on:click="selectParticipations(ranking.nextRound, ranking.rankingType)">
              Make Selection
            </div>
          </div>
        </div>
      </div>
    </div>-->
  </div>
</template>

<script>
  import config from 'client/config';

  import categoryLib from 'client/lib/category.js';
  import sessionLib from 'client/lib/session.js';

  import filter from 'lodash/filter';
  import forEach from 'lodash/forEach';
  import find from 'lodash/find';
  import map from 'lodash/map';

  export default {
    name:'session-dashboard',
    data: function() {
      return {
        rankings: [],
        results: {
          rankingTypes: [],
          rankingSelection: [],
        },
        selections: {
          types: [],
          categorySelection: [],
        },
        tabs: [],
        activeTab: 'results',
        selection: {},
        setLabel: 'panel',
      }
    },
    components: {
      rotation: require('./rotationDashboard.vue').default,
      sessionResults: require('client/components/sessionResults.vue').default,
      sessionSelections: require('./sessionSelections.vue').default,
    },
    created: function() {
      this.setLabel = sessionLib.getSetLabel();
      this.init();
    },
    watch: {
      session: function() {
        this.init();
      }
    },
    computed: {
      session: function() {
        return find(this.$store.state.sessions.items, i => i.id === this.$route.params.sessionId)
      },
      sessions: function() {
        return this.$store.state.sessions.items
      },
      categories: function() {
        return this.$store.state.categories.items
      },
      rounds: function() {
        return this.$store.state.eventDiscipline.rounds
      },
      round: function() {
        const session = find(this.$store.state.sessions.items, i => i.id === this.$route.params.sessionId)
        return find(this.$store.state.eventDiscipline.rounds, i => i.id === session.roundId)
      },
      discipline: function() {
        return this.$store.state.eventDiscipline.discipline
      },
      rotationType: function() {
        return sessionLib.getSessionRotationType(this.session.id)
      }
    },
    methods: {
      init: function() {
        this.rankings = filter(this.round.rankings, i => i.enabled && i.nextRound && i.nextRound.length > 0)

        let selection = {};
        forEach(this.rankings, ranking => {
          selection[ranking.rankingType] = {
            categoryId: undefined,
            sessionId: undefined,
            rotation: undefined,
            set: 1,
            exerciseTypeId: undefined,
          };
        });
        this.selection = selection

        const {rankingTypes, categories} = sessionLib.getRankingTypes(this.session)
        this.results.rankingTypes = rankingTypes

        this.results.rankingSelection =
          map(categories, category => sessionLib.getRankingSelections(rankingTypes, category))

        const selectionTypes = sessionLib.getSelectionTypes(this.session)
        this.selections.types = selectionTypes
        this.selections.categorySelection = categories.map(
          c => sessionLib.getCategorySelections(this.round, selectionTypes, c))

        const tabs = [
          {
            name: 'results',
            label: this.$t('results'),
          },
        ]
        if (selectionTypes.length) {
          tabs.push({
            name: 'selections',
            label: this.$t('selection'),
          })
        }
        this.tabs = tabs
      },
      setActiveTab: function(tab) {
        this.activeTab = tab
      },
      getRound: function(roundId) {
        return find(this.rounds, i => i.id === roundId)
      },
      getCategory: function(categoryId) {
        return find(this.categories, item => item.id === categoryId)
      },
      getSelectionSessions: function(ranking) {
        return filter(this.sessions, session => {
          const sessionCategory = find(session.categories, item =>
            item.categoryId === this.selection[ranking.rankingType].categoryId)
          return session.roundId === ranking.nextRound && sessionCategory !== undefined;
        });
      },
      getSelectionSession: function(rankingType) {
        console.log(rankingType);
        return find(this.sessions, session => session.id === this.selection[rankingType].sessionId)
      },
      getSelectionExerciseTypes: function(ranking) {
        if (! this.selection[ranking.rankingType].categoryId) {
          return []
        }

        return categoryLib.getFormatExerciseTypes(this.selection[ranking.rankingType].categoryId, ranking.nextRound)
      },
      selectParticipations: function(toRoundId, rankingType) {
        this.$http.post(config.root + '/categories/' + this.selection[rankingType].categoryId + '/participationSelection', {
          fromRoundId: this.round.id,
          toRoundId: toRoundId,
          rankingType: rankingType,
          sessionId: this.selection[rankingType].sessionId,
          rotation: this.selection[rankingType].rotation,
          set: this.selection[rankingType].set,
          exerciseTypeId: this.selection[rankingType].exerciseTypeId,
        }).then((result) => {
          console.log(result);
        });
      },
      switchStatus: function(status) {
        this.$store.dispatch('session.setStatus', { sessionId: this.session.id, newStatus: status})
      }
    }
  }
</script>

<style scoped>
</style>
