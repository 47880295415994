<template>
  <form action="#">
    <div class="o-layout">
      <div class="o-layout__item u-1-of-2-at-medium">
        <sceTextField v-model="name" label="name" name="name"
                      v-validate data-vv-rules="required" :class="{'is-invalid': errors.has('name')}" />

        <sceSelect v-model="type" label="type" :options="$options.displayTypes" />

        <div class="c-input c-input--text">
          <input v-model="active" id="active" type="checkbox" class="c-input__field c-input__field-checkbox" />
          <label for="active" class="c-input__label c-input__label-checkbox">{{$t('active')}}</label>
        </div>
      </div>
      <div class="o-layout__item u-1-of-2-at-medium" ref="previewWrapper">
        <div v-if="display" :style="'{ width: ' + display_width + 'px; height: ' + display_height + 'px; }'">
          <iframe ref="preview" :src="iframeLink" width="100%" height="100%" scrolling="no">
          </iframe>
        </div>
        <div v-if="display" class="c-button c-button--primary" v-on:click="fullscreen()">{{$t('display.fullscreen')}}</div>
      </div>
    </div>

    <div class="o-layout u-margin-top-medium" v-if="display">
      <div class="o-layout__item">
        <div class="c-input">
          <label class="c-input__label" for="link">{{$t('display.page.link')}}</label>
          <input v-model="link" class="c-input__field" name="link" type="text" id="link" ref="link" readonly />
        </div>
        <div class="c-button c-button--primary u-margin-top-small" v-on:click="copyLink()">{{$t('copy')}}</div>
      </div>
    </div>

    <div class="o-layout u-margin-top-medium">
      <div class="o-layout__item u-1-of-1">
        <h3 class="c-title c-title--page-section">{{$t('display.discipline')}}</h3>
        <div class="c-input" v-for="eventDiscipline in eventDisciplines" :key="eventDiscipline.id">
          <input v-model="eventDisciplinesSelected" :id="'eventDiscipline-' + eventDiscipline.id" :value="eventDiscipline.id"
                 type="checkbox" class="c-input__field c-input__field-checkbox" />
          <label :for="'eventDiscipline-' + eventDiscipline.id" class="c-input__label c-input__label-checkbox">
            {{eventDiscipline.name}}
          </label>
        </div>
      </div>
    </div>

    <div class="o-layout u-margin-top-medium" v-if="type === 'regular'">
      <div class="o-layout__item u-1-of-3-at-medium u-1-of-2-at-small">
        <h3 class="c-title c-title--page-section">{{$t('display.scores')}}</h3>
        <div class="c-input">
          <input v-model="config.score.active" id="score-active" type="checkbox" class="c-input__field c-input__field-checkbox" />
          <label for="score-active" class="c-input__label c-input__label-checkbox">{{$t('display.score.active')}}</label>
        </div>
        <div class="c-input">
          <input v-model="config.score.announce" id="announce" type="checkbox" class="c-input__field c-input__field-checkbox" />
          <label for="announce" class="c-input__label c-input__label-checkbox">{{$t('display.score.announce')}}</label>
        </div>
        <div class="c-input" :class="{'is-invalid': errors.has('score.minTime')}">
          <label class="c-input__label" for="score.minTime">{{$t('display.score.minTime')}}</label>
          <input v-model="config.score.time.min" class="c-input__field" id="score.minTime" name="score.minTime"
                 type="number" min="1" max="600" step="1" v-validate data-vv-rules="numeric|min_value:1|max_value:600" />
        </div>
        <div class="c-input" :class="{'is-invalid': errors.has('score.maxTime')}">
          <label class="c-input__label" for="score.maxTime">{{$t('display.score.maxTime')}}</label>
          <input v-model="config.score.time.max" class="c-input__field" id="score.maxTime"
                 name="score.maxTime" type="number" min="1" max="600" step="1"
                 v-validate data-vv-rules="numeric|min_value:1|max_value:600" />
        </div>
        <div class="c-input" :class="{'is-invalid': errors.has('score.set')}">
          <label class="c-input__label" for="score.set">{{$t('set')}}</label>
          <select class="c-input__field c-input__field-select" v-model="config.score.set" id="score.set" name="score.set"
                  v-validate data-vv-rules="required">
            <option value="0">
              {{$t('all')}}
            </option>
            <option value="1">1</option>
            <option value="2">2</option>
            <option value="3">3</option>
            <option value="4">4</option>
          </select>
        </div>
        <sceMultiSelect v-model="config.score.exerciseTypes" :options="exerciseTypes" name="exercises"
                        inputName="exercisesField" placeholder="select.allExercises" label="exercises" />
        <!--<div class="c-input"  :class="{'is-invalid': errors.has('score.exerciseType')}">
          <label class="c-input__label" for="score.exerciseType">{{$t('exercise')}}</label>
          <select class="c-input__field c-input__field-select" v-model="config.score.exerciseTypeId"
                  id="score.exerciseType" v-validate data-vv-rules="required">
            <option value="all">
              {{$t('all')}}
            </option>
            <option v-for="exerciseType of exerciseTypes" :key="exerciseType.id" :value="exerciseType.id">
              {{$t('exercise.type.' + exerciseType.id)}}
            </option>
          </select>
        </div>-->
      </div>
      <div class="o-layout__item u-1-of-3-at-medium u-1-of-2-at-small">
        <h3 class="c-title c-title--page-section">{{$t('display.rankings')}}</h3>
        <div class="c-input">
          <input v-model="config.ranking.active" id="ranking-active" type="checkbox"
                 class="c-input__field c-input__field-checkbox" />
          <label for="ranking-active" class="c-input__label c-input__label-checkbox">
            {{$t('display.ranking.active')}}
          </label>
        </div>
        <div class="c-input" :class="{'is-invalid': errors.has('ranking.time')}">
          <label class="c-input__label" for="ranking.time">{{$t('display.ranking.time')}}</label>
          <input v-model="config.ranking.time" class="c-input__field" id="ranking.time" type="number" min="5" max="120" step="1"
                 v-validate data-vv-rules="numeric|min_value:1|max_value:120" />
        </div>
        <div class="c-input" v-for="type in $options.rankingTypes" :key="type">
          <input v-model="config.ranking.types" :id="'ranking-'+type" type="checkbox"
                 class="c-input__field c-input__field-checkbox" :value="type" />
          <label :for="'ranking-'+type" class="c-input__label c-input__label-checkbox">
            {{$t('rankingType.' + type)}}
          </label>
        </div>
      </div>
      <div class="o-layout__item u-1-of-3-at-medium u-1-of-2-at-small">
        <h3 class="c-title c-title--page-section">{{$t('display.logos')}}</h3>
        <div class="c-input">
          <input v-model="config.logo.active" id="logo-active" type="checkbox"
                 class="c-input__field c-input__field-checkbox" />
          <label for="logo-active" class="c-input__label c-input__label-checkbox">
            {{$t('display.logo.active')}}
          </label>
        </div>
        <div class="c-input" :class="{'is-invalid': errors.has('logo.time')}">
          <label class="c-input__label" for="logo.time">{{$t('display.logo.time')}}</label>
          <input v-model="config.logo.time" class="c-input__field" id="logo.time" type="number" min="1" max="120" step="1"
                 v-validate data-vv-rules="numeric|min_value:1|max_value:120" />
        </div>
      </div>
      <div class="o-layout__item u-1-of-3-at-medium u-1-of-2-at-small">
        <h3 class="c-title c-title--page-section">{{$t('display.timers')}}</h3>
        <div class="c-input">
          <input v-model="config.timer.active" id="timer-active" type="checkbox"
                 class="c-input__field c-input__field-checkbox" />
          <label for="timer-active" class="c-input__label c-input__label-checkbox">
            {{$t('display.timer.active')}}
          </label>
        </div>
      </div>
    </div>

    <div class="o-layout u-margin-top-medium" v-if="type === 'panel'">
      <div class="o-layout__item u-1-of-4-at-small u-1-of-2">
        <div class="c-input"  :class="{'is-invalid': errors.has('panel.set')}">
          <label class="c-input__label" for="score.set">{{$t('set')}}</label>
          <select class="c-input__field c-input__field-select" v-model="config.score.set" id="panel.set" name="panel.set"
                  v-validate data-vv-rules="required">
            <option :value="1">1</option>
            <option :value="2">2</option>
            <option :value="3">3</option>
            <option :value="4">4</option>
          </select>
        </div>
      </div>
      <div class="o-layout__item u-1-of-4-at-small u-1-of-2">
        <div class="c-input"  :class="{'is-invalid': errors.has('panel.exerciseType')}">
          <label class="c-input__label" for="score.exerciseType">{{$t('exercise')}}</label>
          <select class="c-input__field c-input__field-select" v-model="config.score.exerciseTypeId"
                  id="panel.exerciseType2" name="panel.exerciseType2" v-validate data-vv-rules="required">
            <option v-for="exerciseType of exerciseTypes" :key="exerciseType.id" :value="exerciseType.id">
              {{$t('exercise.type.' + exerciseType.id)}}
            </option>
          </select>
        </div>
      </div>
    </div>

    <div class="o-layout u-margin-top-medium u-margin-bottom-large">
      <div class="o-layout__item">
        <h3 class="c-title c-title--page-section">{{$t('display.layout')}}</h3>
      </div>

      <div class="o-layout__item u-2-of-5-at-medium u-1-of-1">
        <div class="c-input"  :class="{'is-invalid': errors.has('templates')}">
          <label class="c-input__label" for="templates">{{$t('display.templates')}}</label>
          <select class="c-input__field c-input__field-select" v-model="displaySetId" id="templates" name="templates"
                  v-validate data-vv-rules="required">
            <option v-for="template in templates" :key="template.id" :value="template.id">
              {{template.global ? $t(template.id) : template.name}}
            </option>
          </select>
        </div>
      </div>

      <div class="o-layout__item u-1-of-5-at-medium u-1-of-4-at-small u-1-of-2">
        <label class="c-input__label">{{$t('display.colors.background')}}</label>
        <div class="colorbox" :style="{'background-color': config.colors.background}" v-on:click="editColor('background')"></div>
        <div>{{config.colors.background}}</div>
      </div>
      <div class="o-layout__item u-1-of-5-at-medium u-1-of-4-at-small u-1-of-2">
        <label class="c-input__label">{{$t('display.colors.forground')}}</label>
        <div class="colorbox" :style="{'background-color': config.colors.forground}" v-on:click="editColor('forground')"></div>
        <div>{{config.colors.forground}}</div>
      </div>
      <div class="o-layout__item u-1-of-5-at-medium u-1-of-4-at-small">
        <div class="c-button c-button--primary" v-on:click="resetColors()">{{$t('reset.colors')}}</div>
      </div>
    </div>

    <footer class="c-footer c-footer--bottom-sticky c-footer--bottom-sticky-fixed-height o-layout">
      <div class="o-layout__item u-1-of-2-at-tiny">
        <button v-if="display" class="c-button c-button--secondary c-button--large" v-on:click.prevent="remove()">
          {{$t('delete')}}
        </button>
      </div>

      <div class="o-layout__item u-1-of-2-at-tiny u-text-right">
        <router-link exact :to="{ name: 'admin.event.display.list'}">
          <button class="c-button c-button--ghost c-button--large">{{$t("cancel")}}</button>
        </router-link>
        <button class="c-button c-button--primary c-button--large" v-on:click.prevent="submit()">
          {{$t('save')}}
        </button>
      </div>
    </footer>

    <modal :shown="colorBox !== undefined" @closed="colorBox = undefined">
      <div v-if="colorBox">
        <color-picker v-model="colorBox.color" class="u-margin-bottom-small" />
        <button v-on:click.prevent="closeModal()" class="c-button c-button--secondary">
          {{$t('panel.closeModal.button')}}
        </button>
        <button v-on:click.prevent="confirmColor()"
                class="c-button c-button--primary">
          {{$t('panel.continue.button')}}
        </button>
      </div>
    </modal>
  </form>
</template>

<script type="text/babel">
  import config from 'client/config'
  import form from 'client/lib/form'

  import cloneDeep from 'lodash/cloneDeep';
  import concat from 'lodash/concat';
  import find from 'lodash/find';
  import isEmpty from 'lodash/isEmpty';
  import merge from 'lodash/merge'
  import sortBy from 'lodash/sortBy'

  import {round} from 'mathjs';

  import vue_color from 'vue-color';

  export default {
    name: "display",
    components: {
      'modal': require('client/components/modal.vue').default,
      'color-picker': vue_color.Sketch,
    },
    rankingTypes: ['AA', 'TEAM-AA', 'EVENTS'],
    displayTypes: form.idNamePairs(['regular', 'panel'], 'displayType.'),
    data: function () {
      return {
        link: null,
        iframeLink: null,
        ratio: screen.width / screen.height,
        display_height: 150,
        display_width: 200,
        name: null,
        type: 'regular',
        active: false,
        displaySetId: null,
        id: null,
        eventDisciplinesSelected: [],
        config: {
          templates: 'default',
          colors: {
            background: '#051931',
            forground: '#ffffff',
            accent: '#fde600',
          },
          score: {
            active: true,
            announce: false,
            time: {
              min: 12,
              max: 40,
            },
            set: 0,
            exerciseTypeId: 'all',
            exerciseTypes: []
          },
          ranking: {
            active: true,
            time: 10,
            types: ['AA'],
          },
          logo: {
            active: true,
            time: 4,
          },
          timer: {
            active: true,
          }
        },
        colorBox: undefined,
        exerciseTypes: [],
      };
    },
    computed: {
      display: function () {
        const displayId = this.$route.params.displayId;
        if (displayId) {
          return find(this.$store.state.displays.items, item => {
            return item.id === displayId;
          });
        }
        return null;
      },
      event: function () {
        return this.$store.state.events.current
      },
      eventDisciplines: function() {
        return this.$store.state.eventDisciplines.items;
      },
      templates: function() {
        return this.$store.state.displayTemplates.items;
      },
    },
    created: function () {
      this.exerciseTypes = [];
      this.setDisplay(this.display);
    },
    watch: {
      eventDisciplinesSelected: function() {
        this.setExerciseTypes()
      }
    },
    mounted: function() {
      this.display_width = this.$refs.previewWrapper.clientWidth - 40;
      this.display_height = round(this.display_width/this.ratio);
    },
    methods: {
      editColor: function(color) {
        this.colorBox = {
          label: color,
          color: {
            hex: this.config.colors[color]
          }
        };
      },
      confirmColor: function() {
        this.config.colors[this.colorBox.label] = this.colorBox.color.hex;
        this.colorBox = undefined;
      },
      closeModal: function() {
        this.colorBox = undefined;
      },
      resetColors: function() {
        this.config.colors = {
          background: '#051931',
          forground: '#ffffff',
          accent: '#fde600',
        };
      },
      copyLink: function() {
        this.$refs.link.select();
        document.execCommand("copy");
      },
      setDisplay: function () {
        if (this.display) {
          this.id = this.display.id;
          this.name = this.display.name;
          this.type = this.display.type || 'regular';
          this.active = this.display.active;
          this.displaySetId = this.display.displaySetId;

          this.config = merge(this.config, cloneDeep(this.display.config))

          if (this.display.eventDisciplines && this.display.eventDisciplines.length) {
            this.eventDisciplinesSelected = cloneDeep(this.display.eventDisciplines);
          } else if (this.display.eventDisciplineId) {
            this.eventDisciplinesSelected = [this.display.eventDisciplineId];
          }

          this.setExerciseTypes(true)

          const type = this.display.type === 'panel' ? 'panel' : 'view'

          this.iframeLink = '/display.html#/display/' + this.event.id + '/' + type + '/' + this.display.id
          this.link = this.$store.state.environment.baseUrl + this.iframeLink
        } else {
          this.exerciseTypes = []
        }
      },
      setExerciseTypes: function(init = false) {
        const promises = this.eventDisciplinesSelected.map(eventDisciplineId => {
          const eventDiscipline = find(this.eventDisciplines, item => {
            return item.id === eventDisciplineId;
          })
          return this.$http.get(
            config.root + '/disciplines/' + eventDiscipline.disciplineId + '/exerciseTypes')
        })
        Promise.all(promises).then(results => {
          let exerciseTypes = []
          results.forEach(result => {
            exerciseTypes = concat(exerciseTypes, sortBy(result.data, 'index'))
          })
          this.exerciseTypes = exerciseTypes
          if (init) {
            exerciseTypes = []
            console.log(this.config.score.exerciseTypes)
            this.config.score.exerciseTypes.forEach(id => {
              const et = this.exerciseTypes.find(i => i.id === id)
              if (et) {
                exerciseTypes.push(et)
              }
            })
            this.config.score.exerciseTypes = exerciseTypes
          }
        })
      },
      fullscreen: function() {
        this.$refs.preview.webkitRequestFullScreen();
      },
      showTimer: function() {
        return this.config.timer.active && ! isEmpty(this.display.timer);
      },
      submit: function () {
        this.$validator.validateAll().then(() => {
          if (!this.errors.any()) {
            const data = {
              id: this.id,
              eventId: this.event.id,
              eventDisciplines: this.eventDisciplinesSelected,
              name: this.name,
              type: this.type,
              active: this.active,
              displaySetId: this.displaySetId,
              config: cloneDeep(this.config),
            }
            data.config.score.exerciseTypes = this.config.score.exerciseTypes.map(e => e.id)

            this.$emit('submit', data)
          } else {
            this.$notify.warning('validation errors')
          }
        })
      },
      remove: function () {
        this.$emit('remove', this.id);
      }
    }
  };
</script>

<style scoped>
  .colorbox {
    width: 60px;
    height: 40px;
    border: solid 1px darkgrey;
  }

  iframe {
    border: none;
    overflow: hidden;
    background-color: #4a4a4a;
  }
</style>
