<template>
  <router-view ></router-view>
</template>

<script>
  export default {
    name: "wrapper"
  }
</script>

<style scoped>

</style>
