<template>
  <div class="c-main">
    <h2 class="c-title c-title--page">{{ $t('discipline.add') }}</h2>

    <grid :config="{searchFields: ['name']}" :data="disciplines">
      <template slot="gridAdd">
      </template>
      <template slot="gridHeader">
        <div class="o-layout c-list__row u-padding-horizontal-small">
          <div class="o-layout__item u-5-of-6-at-medium">
            {{ $t('name') }}
          </div>
          <div class="o-layout__item u-1-of-6-at-medium">
            &nbsp;
          </div>
        </div>
      </template>
      <template slot="gridData" slot-scope="slotProps">
        <div class="o-layout__item u-5-of-6-at-medium c-list__main-cell">
          {{ slotProps.element.name }}
        </div>
        <div class="o-layout__item u-1-of-6-at-medium">
          <div class="c-button c-button--small c-button--primary"
               v-on:click="create(slotProps.element)">
            {{ $t('select') }}
          </div>
        </div>
      </template>
    </grid>
  </div>
</template>

<script>
import userLib from 'client/lib/user.js'

import filter from 'lodash/filter'
import map from 'lodash/map'
import sortBy from 'lodash/sortBy'

export default {
  name: "discipline-add",
  components: {
    grid: require('client/components/dataGrid.vue').default,
  },
  data: function () {
    return {
      selected: ''
    };
  },
  computed: {
    event: function () {
      return this.$store.state.events.current
    },
    disciplines: function () {
      return sortBy(map(filter(this.$store.state.disciplines.items, item => {
        return userLib.matchTags(item);
      }), item => {
        return {
          id: item.id,
          name: this.$t(item.id),
        }
      }), 'name');
    },
  },
  created: function () {

  },
  methods: {
    create: function (discipline) {
      const eventDiscipline = {
        disciplineId: discipline.id,
        name: this.event.title + ' ' + discipline.name,
        label: discipline.name,
      };

      this.$store.dispatch('eventDiscipline.add', eventDiscipline).then(result => {
        this.$navigation.goto({name: 'admin.event.discipline.settings', params: {disciplineId: result.id}})
      })
    },
  },
};
</script>

<style>
</style>
