<template>
  <div class="c-main">
    <h2 class="c-title c-title--page">{{$t('round.competition_rounds')}}</h2>
    <div class="c-main--flex-1 u-margin-bottom-small">
      <round v-for="round in rounds" :id="round.id" :key="round.index" :rounds="other(rounds, round.id)"/>
    </div>
    <footer class="c-footer c-footer--bottom-sticky c-footer--bottom-sticky-fixed-height o-layout">
      <div class="o-layout__item u-1-of-2-at-tiny">
      </div>

      <div class="o-layout__item u-1-of-2-at-tiny u-text-right">
        <button class="c-button c-button--primary c-button--large" v-on:click.prevent="add()">
          {{$t('round.add')}}
        </button>
      </div>
    </footer>
  </div>
</template>

<script>
  import filter from 'lodash/filter';
  import find from 'lodash/find';
  import map from 'lodash/map';
  import sortBy from 'lodash/sortBy';

  export default {
    name: "rounds-wrapper",
    components: {
      round: require('./round.vue').default,
    },
    computed: {
      rounds: function () {
        return sortBy(this.$store.state.eventDiscipline.rounds, 'index');
      }
    },
    methods: {
      add: function () {
        this.$notify.saveAsync(
            this.$t('round'),
            this.$store.dispatch('round.add', {
              index: this.rounds.length,
              eventDisciplineId: this.$route.params.disciplineId,
            }),
        );
      },
      other: function (rounds, id) {
        const refRound = find(rounds, function (item) {
          return item.id === id;
        });

        return map(filter(rounds, function (round) {
          return round.index > refRound.index;
        }, function (round) {
          return {
            id: round.id,
            index: round.index,
            name: round.name
          }
        }));
      }
    }
  };
</script>

<style scoped>
</style>
